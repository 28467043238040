<template>
	<div class="d-accordion">
		<div
			@click="expand(i)"
			v-for="(item, i) in items"
			:key="i"
			class="d-accordion-item"
			:class="{ expanded: expanded == i }"
		>
			<div style="display: flex">
				{{ item }}
				<i class="fal" :class="{ 'fa-plus': expanded != i, 'fa-minus': expanded == i }"></i>
			</div>
			<div class="expand-content" v-if="expanded == i">
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa error vitae iure
				aliquid aperiam, eaque officia odio. Ducimus distinctio eveniet dolorem rerum
				officia, quibusdam amet totam fuga dolor aliquam eos.
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "imdex-accordion",
	props: {
		items: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			expanded: null
		};
	},
	methods: {
		expand(i) {
			if (i == this.expanded) {
				this.expanded = null;
			} else {
				this.expanded = i;
			}
		}
	}
};
</script>
<style scoped lang="scss">
.d-accordion {
	background: #fff;
	box-shadow: $box-shadow1;
	width: 850px;
	height: 100%;
	.d-accordion-item {
		color: $ww-dark;
		.expand-content {
			margin-top: 25px;
			color: $ww-dark;
			font-weight: normal;
		}

		width: 100%;
		color: $ww-dark;
		font-weight: bold;
		border-bottom: solid 1px $ww-grey;
		&:last-child {
			border-bottom: none !important;
		}
		cursor: pointer;
		i {
			margin-left: auto;
			color: $ww-highlight;
		}
		padding: 15px 15px;
		overflow: hidden;
		&:hover {
			background: $ww-light;
		}
		&.expanded {
			height: unset;
			background: #fff;
		}
	}
}
</style>
