<template>
	<div v-bind="attrs" class="imdex-layout" :class="classes">
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: "imdex-layout",
	inheritAttrs: false,
	props: {
		column: {
			type: Boolean,
			default: false
		},
		row: {
			type: Boolean,
			default: false
		},
		"align-center": {
			type: Boolean,
			default: false
		},
		"align-end": {
			type: Boolean,
			default: false
		},
		"align-start": {
			type: Boolean,
			default: false
		},
		"justify-start": {
			type: Boolean,
			default: false
		},
		"justify-center": {
			type: Boolean,
			default: false
		},
		"justify-end": {
			type: Boolean,
			default: false
		},
		"justify-space-between": {
			type: Boolean,
			default: false
		},
		"fill-height": {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			expanded: null
		};
	},
	computed: {
		attrs() {
			const attrs = { ...this.$attrs };

			// adding class and style for `v-bind`
			//attrs.class = this.$vnode.data.staticClass
			attrs.style = this.$vnode && this.$vnode.data.staticStyle;

			return attrs;
		},
		classes() {
			return {
				"layout-align-center": this["alignCenter"],
				"layout-align-start": this["alignStart"],
				"layout-align-end": this["alignEnd"],
				"layout-justify-center": this["justifyCenter"],
				"layout-justify-start": this["justifyStart"],
				"layout-justify-end": this["justifyEnd"],
				"layout-justify-space-between": this["justifySpaceBetween"],
				"layout-column": this["column"],
				"layout-fill-height": this.fillHeight,
				"layout-row": this["row"]
				// [this.$vnode.data.staticClass]:true
			};
		}
	}
};
</script>
<style scoped lang="scss">
.imdex-layout {
	display: flex;
	&.layout-row {
		flex-direction: row;
	}
	&.layout-column {
		flex-direction: column;
	}
	&.layout-align-center {
		align-items: center;
	}
	&.layout-align-start {
		align-items: flex-start;
	}
	&.layout-align-end {
		align-items: flex-end;
	}
	&.layout-justify-center {
		justify-content: center;
	}
	&.layout-justify-start {
		justify-content: flex-start;
	}
	&.layout-justify-end {
		justify-content: flex-end;
	}
	&.layout-justify-space-between {
		justify-content: space-between;
	}
	&.layout-fill-height {
		height: 100%;
	}
}
</style>
