<template>
	<div
		ref="toast"
		class="imdex-toast animated-fast fadeInUp"
		:class="{
			't-success': type == 'success',
			't-error': type == 'error',
			't-warning': type == 'warning'
		}"
	>
		<i :class="icon"></i>
		<div class="toast-inner">
			<div v-if="title != ''" class="toast-title">{{ title }}</div>
			<span v-if="message != ''">{{ message }}</span>
		</div>
		<div @click="destroy" class="toast-dismiss">
			<i class="fal fa-times"></i>
		</div>
		<div class="toast-progress" ref="progress"></div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
	name: "imdex-toast",
	props: {
		type: {
			type: String,
			default: "primary"
		},
		delay: {
			type: Number,
			default: 5000
		},
		dismissable: {
			type: Boolean,
			default: false
		},
		message: {
			type: String,
			default: ""
		},
		title: {
			type: String,
			default: ""
		},
		leadingIcon: {
			type: String,
			default: ""
		},
		position: {
			type: String,
			default: "top-right"
		}
	},
	data() {
		return {
			progress: 0,
			destroyed: false
		};
	},
	mounted() {
		const prog: any = this.$refs.progress;
		prog.style.transition = `width ${this.delay / 1000}s linear`;
		prog.style.width = "0%";

		setTimeout(() => {
			// Since it is possible to dismiss which calls destroy
			// we check if destroyed has already been called
			if (!this.destroyed) {
				this.destroy();
			}
		}, this.delay);
		setTimeout(() => {
			this.progress = 1;
			prog.style.width = "100%";
		}, 50);
	},
	methods: {
		destroy() {
			const body: any = document.getElementById("toast-container");
			this.$el.classList.add("fadeOutDown");

			setTimeout(() => {
				if (body && this.$el && body.contains(this.$el)) {
					body.removeChild(this.$el);
					this.destroyed = true;
				}
			}, 300);
		}
	},
	computed: {
		icon(): any {
			if (this.leadingIcon != "") {
				return this.leadingIcon;
			} else {
				if (this.type == "success") {
					return "fal fa-check";
				}
				if (this.type == "error") {
					return "fal fa-exclamation-circle";
				}
				if (this.type == "warning") {
					return "fal fa-exclamation-triangle";
				}
				if (this.type == "primary") {
					return "fa fa-info-circle";
				}
				return "";
			}
		}
	}
});
</script>
<style lang="scss">
.imdex-toast {
	position: relative;
	overflow: hidden;
	background: $ww-primary;
	display: flex;
	align-items: center;
	gap: 15px;
	padding: 15px;
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
	z-index: 100000001;
	i {
		color: $ww-dark;
		font-size: 15pt;
	}
	&.t-success {
		background: $ww-success;
		i {
			color: $ww-dark;
		}
	}
	&.t-warning {
		background: $ww-warning;
		i {
			color: $ww-dark;
		}
	}
	&.t-error {
		background: $ww-error;
		i {
			color: #fff;
		}
		color: #fff;
		.toast-inner {
			.toast-title {
				color: #fff !important;
			}
			span {
				color: #fff !important;
			}
		}

		.toast-dismiss {
			i {
				color: #fff;
			}
		}
		.toast-progress {
			background: #ffffff70;
		}
	}
	.toast-inner {
		.toast-title {
			font-size: 12pt;
			color: $ww-dark;
		}
		span {
			font-size: 0.8em;
		}
	}
	.toast-dismiss {
		margin-left: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		cursor: pointer;
		i {
			color: $ww-dark;
			transition: all 0.3s ease;
			font-size: 14pt;
		}
		&:hover {
			i {
				color: #fff;
			}
		}
	}
	.toast-progress {
		position: absolute;
		bottom: 0px;
		left: 0;
		height: 3px;
		background: #00000050;
	}
}
</style>
