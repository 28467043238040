<template>
	<div class="d-sidebar" :class="{ collapsed }">
		<!-- <div @click="collapsed=!collapsed" class="expand-btn">
            <i class="fal" :class="{'fa-bars':collapsed, 'fa-times':!collapsed}"></i>
        </div>
        <hr> -->
		
		<slot></slot>
	</div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useAuthStore } from "@/store/authStore";
export default defineComponent({
	setup() {
		const authStore = useAuthStore();
		const user = authStore.user;
		return {
			user,
			authStore
		};
	},
	name: "NavigationSidebar",
	props: {
		navigationItems: {
			type: Array,
			default: () => []
		},
		isOpen: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			collapsed: false,
			childrenExpanded: []
		};
	},
	mounted() {
		this.collapsed = this.isOpen;
	},
	methods: {
	},
	computed: {

		
	},
	watch: {
		isOpen(v) {
			this.collapsed = v;
		}
	}
});
</script>
<style scoped lang="scss">
.d-sidebar {
	background: #fff;
	width: 250px;
	height: 100%;
	transition: all 0.3s ease;
	transform-origin: 0%;
	position: relative;
	overflow: hidden;

	//   box-shadow: 5px 0px 10px rgba(0,0,0,0.4);
	&.collapsed {
		width: 0px;
	}
	hr {
		width: 90%;
		height: 1px;
		background: $ww-grey;
		opacity: 0.2;
		border: none;
		margin-block-start: 0 !important;
	}
	.expand-btn {
		width: 64px;
		color: $ww-dark;
		font-size: 15pt;
		height: 64px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&:hover {
			color: $ww-tertiary-light;
		}
	}
	.navigation-items {
		height: calc(100%);
		padding: 8px;
		position: relative;
		border: solid 1px $ww-grey;
		.bottom {
			position: absolute;
			width: calc(100% - 16px);
			bottom: 25px;
			i.accordion {
				margin-left: auto;
			}
		}
	}

	.navigation-item {
		color: $ww-dark;
		text-decoration: none;
		white-space: nowrap;
		&:hover {
			color: #fff !important;
		}

		cursor: pointer;
		display: flex;
		height: 45px;
		align-items: center;
		padding: 0px 16px;
		color: $ww-dark;
		font-weight: bold;
		transition: all 0.3s ease;
		border-radius: 3px;
		font-size: 12pt;
		margin-bottom: 4px;
		&:hover {
			background: $ww-highlight;

			color: #fff;
		}
		&.active {
			background: $ww-highlight;

			color: #fff;
		}
	}
	.navigation-children {
		.navigation-item {
			margin-left: 25px !important;
			height: 35px !important;
		}
	}
}
</style>
