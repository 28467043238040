<template>
    <div class="dialog">
        <input
			ref="avatar-image"
			style="opacity: 0; pointer-events: none; position: absolute; user-select: none"
			type="file"
			@change="onFileChange"
		/>
        <d-dialog v-model:value="cropperDialog">
			<div class="cropper">
				<div class="cropper-top">
					<d-btn
						style="margin-left: auto"
						icon
						text
						compact
						@click="cropperDialog = false"
						><i class="fal fa-times"></i
					></d-btn>
				</div>
				<div class="cropper-body">
					<div class="no-image" v-if="url == ''">
						<i class="fal fa-search"></i> Browse
					</div>
					<div class="cropper-crop">
						<img
							ref="cropper-image"
							v-show="false"
							:src="previewUrl != '' ? previewUrl : url"
						/>
					</div>
				</div>
				<div class="cropper-actions">
					<d-btn small text @click="cropperDialog = false">Cancel</d-btn>
					<d-btn small @click="finishCrop">Confirm</d-btn>
				</div>
			</div>
		</d-dialog>
        <div class="dialog-top">
            <div class="dialog-title">
              Add Pet  
            </div>
            <div class="dialog-close">
                <d-btn @click="close" mini icon><i class="fal fa-times"></i></d-btn>
            </div>
        </div>
        <div class="dialog-body pet-add-body">
            <div class="pet-image">
					<div
						v-if="pet.profilePicUrl == null && previewUrl == ''"
						:class="{
							'has-default-color':
								pet.defaultProfilePicColor != null || pet.name != ''
						}"
                        
						:style="`background:${pet.defaultProfilePicColor}`"
						class="pet-avatar"
					>
						<i v-if="pet.name == null || pet.name == ''" class="fa fa-pet"></i>
						<div v-if="pet.name != null && pet.name.length > 0">
							{{ pet.name[0].toUpperCase() }}
						</div>
					</div>
					<div
						v-else-if="
							(!avatarLoading && pet.profilePicUrl != null) || previewUrl != ''
						"
						class="pet-avatar"
                        
					>
						<img
							id="profile-pic"
							:src="previewUrl != '' ? previewUrl : profilePicMap[pet.profilePicUrl]"
							alt=""
						/>
					</div>
					<div v-else class="pet-avatar">
						<d-spinner size="2" width="50"></d-spinner>
					</div>
					<d-btn @click="changeAvatar" text compact
						>Change</d-btn
					>
			</div>
			<div class="pet-info">
				<d-text-field label="Pet Name" v-model:value="pet.name"></d-text-field>
				<div class="row">
					<d-text-field style="flex:.5" label="Age" :min="1" :max="20" type="number" v-model:value="pet.age"></d-text-field>
					<d-select style="flex:2" label="Breed" :items="availableBreeds" v-model:value="pet.breed"></d-select>
				</div>
				
				
			</div>
        </div>
		<div v-if="errorMessage != ''">{{ errorMessage }}</div>
        <div class="dialog-actions">
            <div class="dialog-cancel">
                <d-btn @click="close" small text>Cancel</d-btn>
            </div>
            <div class="dialog-submit">
                <d-btn @click="submitAddPet" :loading="isLoading" small>Submit</d-btn>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, computed, ref, reactive, watch, defineEmits, } from 'vue';
import { storeToRefs } from 'pinia';
import Cropper from "cropperjs";
import axios from 'axios';
import { useApiStore } from '@/store/apiStore';
import {useAuthStore} from '@/store/authStore';

export default defineComponent({
    name:'AddPet',
    components:{
        Cropper
    },
    setup(_, { emit }) {
		const api = useApiStore();
        const close = () =>{
            emit('close');  
        }
		const {user} = storeToRefs(useAuthStore());
        return {
			user,
			api,
            close
        }
    },
    data(){
        return {
			errorMessage:'',
            cropperDialog:false,
            previewUrl:'',
            cropper:null as any,
			isLoading:false,
            url:'',
            pet:{
                name:"",
				age:1,
				breed:''
            },
            avatarLoading:false,
			imageBlob:null as any
        }
    },
	computed:{
		availableBreeds(){
			return [
				{
					text:'American Shorthair',
					value:'american_shorthair'
				},
				{
					text:'Tuxedo',
					value:'tuxedo'
				},
				{
					text:'Siamese',
					value:'siamese'
				},
				{
					text:'Maine Coon',
					value:'maine_coon'
				},
				{
					text:'Persian',
					value:'persian'
				},
				{
					text:'Sphynx',
					value:'sphynx'
				},
				{
					text:'Ragdoll',
					value:'ragdoll'
				},
				{
					text:'Siamese',
					value:'siamese'
				},
				{
					text:'Manx',
					value:'manx'
				},
				{
					text:'Chartreux',
					value:'chartreux'
				},
				{
					text:'Other',
					value:'other'
				},
			]
		}
	},
    methods:{
		async submitAddPet(){
			this.isLoading = true;
			let FD = new FormData();
			FD.append('pet', this.imageBlob, 'pet.png');
			FD.append('name', this.pet.name);
			FD.append('age', this.pet.age.toString());
			FD.append('breed', this.pet.breed);
			FD.append('userId', this.user.id);

			try{
				let {data} = await this.api.post({endpoint:'/api/pets/add', post:FD});
				this.isLoading = false;
				this.$emit('close');
			}catch(e: any){
				this.isLoading = false;
				this.errorMessage =JSON.stringify(e)
				//this.errorMessage = e.response.data.message;
			}

		},
        changeAvatar() {
			(this.$refs["avatar-image"] as HTMLElement).click();
		},
        async finishCrop() {
			this.avatarLoading = true; // eslint-disable-next-line
			this.cropper.getCroppedCanvas().toBlob(async (blob: any) => {
				this.imageBlob = blob;
				const formData = new FormData();
				formData.append("pet", blob, "pet.png");
				const urlCreator = window.URL || window.webkitURL;
				this.previewUrl = urlCreator.createObjectURL(blob); // eslint-disable-next-line
                console.log('FINISHED CROP');
				// if (this.isMe) {
				// 	await this.api.post({ endpoint: `/user/avatar-upload`, post: formData });
				// } else {
				// 	await this.api.post({
				// 		endpoint: `/user/avatar-upload/${this.$route.params.id}`,
				// 		post: formData
				// 	});
				// }
			});
			this.cropperDialog = false;
		}, // eslint-disable-next-line
		onFileChange(e: any) {
			this.previewUrl = '';
			this.url = "";
			const file = e.target.files[0];
			const validImageTypes = ["image/jpeg", "image/png"];
			if (!validImageTypes.includes(file.type)) {
				return;
			}
			// Check file size
			const maxSizeInBytes = 3 * 1024 * 1024; // 3MB
			if (file.size > maxSizeInBytes) {

				return;
			}
			this.cropperDialog = true;
			

			this.$nextTick(() => {
				this.url = URL.createObjectURL(file);
				this.previewUrl = URL.createObjectURL(file);
				console.log('url', this.url);
				const image: HTMLImageElement = this.$refs["cropper-image"] as HTMLImageElement;

				this.$nextTick(() => {
					if (this.cropper) {
						this.cropper = new Cropper(image, {
							aspectRatio: 16 / 16,
							minContainerHeight: 300,
							viewMode: 1,
							background: false,
							guides: false
						});
					} else {
						this.cropper = new Cropper(image, {
							aspectRatio: 16 / 16,
							minContainerHeight: 300,
							viewMode: 1,
							background: false,
							guides: false
						});
					}
				});
			});
		},
    }
})
</script>
<style lang="scss">
.pet-add-body{
	display:flex; 
	align-items:center; 
	gap:25px;
	@include media-query($small){
		flex-direction: column;
	}
}
.pet-info{
	display:flex;
	flex:1;
	flex-direction: column;
	justify-content: flex-start;
	padding: 0px 25px;
	gap:15px;
	.row{
		display:flex;
		gap:10px;
	}
}
.pet-image {
    max-width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
    .pet-avatar {
        height: 138px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25pt;
        color: $ww-dark;
        width: 138px;
        border-radius: 138px;
        background: $ww-grey;
        overflow: hidden;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        &.has-default-color {
            font-size: 45pt;
            color: #00000030;
        }
    }
}
.cropper {
	background: #fff;
	border-radius: 5px;
	box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.4);
	
	min-height: 300px;
	.cropper-top {
		height: 45px;
		display: flex;
		align-items: center;
		padding: 0px 15px;
	}
	.cropper-body {
		.no-image {
			display: flex;
			height: 300px;
			background: $ww-grey;
			align-items: center;
			justify-content: center;
			color: #808080;
			font-size: 17pt;
			width: 100%;
			cursor: pointer;
			i {
				margin-right: 15px;
			}
		}
		.cropper-crop {
			display: block;
			img {
				display: block;
				width: 100%;
				height: 100%;
				/* This rule is very important, please don't ignore this */
				max-width: 100%;
			}
		}
	}
	.cropper-actions {
		display: flex;
		align-items: center;
		gap: 16px;
		justify-content: flex-end;
		width: 100%;
		padding: 15px;
	}
}
</style>