<template>
	<div class="d-tab" :class="{ active: value == $.vnode.key }">
		<i class="fa" v-if="prependIcon != ''" :class="prependIcon" style="padding-right: 10px"></i>
		<slot></slot>
		<i style="padding-left: 10px" class="fa" v-if="appendIcon != ''" :class="appendIcon"></i>
		<div class="d-bar"></div>
	</div>
</template>
<script>
export default {
	name: "imdex-tab",
	props: {
		prependIcon: {
			type: String,
			default: ""
		},
		appendIcon: {
			type: String,
			default: ""
		}
	},
	computed: {
		value() {
			return this.$parent.$props.value;
		}
	},
	methods: {
		clickme() {
			this.$emit("inp", this.$.vnode.key);
		}
	}
};
</script>
<style scoped lang="scss">
.d-tab {
	padding: 16px 32px;
	color: $ww-dark;
	position: relative;
	font-weight: 500;
	text-align: center;
	i {
		color: $ww-dark;
	}
	cursor: pointer;
	&:hover {
		color: $ww-highlight;
		i {
			color: $ww-highlight;
		}
		.d-bar {
			opacity: 1;
		}
	}
	&.active {
		color: $ww-highlight;
		i {
			color: $ww-highlight;
		}
		.d-bar {
			opacity: 1;
		}
	}
	.d-bar {
		opacity: 0;
		position: absolute;
		bottom: 0;
		height: 3px;
		width: 100%;
		left: 0;
		background: $ww-secondary;
	}
}
</style>
