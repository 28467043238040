<template>
	<div class="d-card">
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: "imdex-card",
	props: {
		items: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			expanded: null
		};
	},
	methods: {
		expand(i) {
			if (i == this.expanded) {
				this.expanded = null;
			} else {
				this.expanded = i;
			}
		}
	},
	computed: {},
	watch: {}
};
</script>
<style scoped lang="scss">
.d-card {
	background: #fff;
	box-shadow: $box-shadow1;
	width: 850px;
	height: 100%;
	padding: 24px;
	h2 {
		font-size: 15pt;
		color: $ww-dark;
		font-weight: bold;
	}
}
</style>
