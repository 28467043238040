<template>
	<div class="d-progress"></div>
</template>
<script>
export default {
	name: "imdex-progress",
	props: {
		items: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			expanded: null
		};
	},
	methods: {
		expand(i) {
			if (i == this.expanded) {
				this.expanded = null;
			} else {
				this.expanded = i;
			}
		}
	}
};
</script>
<style scoped lang="scss">
.d-progress {
	height: 30px;
	width: 30px;
	border-radius: 100px;
	border: solid 3px $ww-tertiary-light;
}
</style>
