<template>
	<div @click="$emit('update:value', !value)" ref="menu" class="imdex-menu">
		<slot name="template"></slot>
		<transition enter-active-class="zoomIn" leave-active-class="fadeOutDown">
			<div v-if="value" ref="dropdown" class="imdex-dropdown-menu animated-fast">
				<slot name="dropdown"></slot>
			</div>
		</transition>
	</div>
</template>
<script >
import { defineComponent } from "vue";
export default defineComponent({
	name: "imdex-menu",
	data() {
		return {
			isOpen: false
		};
	},
	props: {
		value: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		value(v) {
			this.$nextTick(() => {
				if (v) {
					console.log(v);
				}
			});
		}
	}
});
</script>
<style lang="scss">
.imdex-menu {
	position: relative;

	.imdex-dropdown-menu {
		position: fixed;
		background: #fff;
		z-index: 10000;
		ul {
			li {
				background: #fff;
				padding: 5px 15px;
				white-space: nowrap;
				color: $ww-dark;
				transition: all 0.3s ease;
				cursor: pointer;
				&:hover {
					background: $ww-grey;
				}
			}
		}
	}
}
</style>
