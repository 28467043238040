<template>
	<div style="width: 100%">
		<div class="d-textarea" :class="{ 'dark-d': dark }">
			<label :class="{ filled: value != '' }">{{ label }}</label>
			<textarea
				@input="$emit('input', $event.target.value)"
				:name="label"
				:type="type"
			></textarea>
			<div class="bottom-line"></div>
		</div>
	</div>
</template>
<script>
export default {
	name: "imdex-textarea",
	props: {
		dark: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: "#3e80e3"
		},
		value: {
			type: String,
			default: ""
		},
		label: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "text"
		},
		large: {
			type: Boolean,
			default: false
		},
		"x-large": {
			type: Boolean,
			default: false
		},
		fab: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		}
	}
};
</script>
<style lang="scss">
.d-textarea {
	width: 100%;
	position: relative;
	margin-top: 25px;
	flex: 1;
	padding: 10px;
	border-radius: 10px;

	textarea {
		color: #000;
		padding: 5px 0px;
		font-size: 12pt;
		width: 100%;
		&:focus {
			outline: none;
		}
	}
	&:focus-within {
		label {
			max-width: 153%;
			transform: translateY(-26px) translateX(0px) scale(0.7, 0.7);
		}
		.bottom-line {
			background: #000;
		}
	}
	.bottom-line {
		height: 1px;
		background: #00000030;
		width: 100%;

		position: absolute;
		bottom: 0;
		left: 0;
	}
	.filled {
		max-width: 153%;
		transform: translateY(-26px) translateX(0px) scale(0.7, 0.7) !important;
	}
	label {
		max-width: 90%;
		transform-origin: top left;
		transition: all 0.3s ease;
		width: 200%;
		text-align: left;
		pointer-events: none;
		position: absolute;
		left: 10px;
		top: 10px;
		overflow: hidden;
		padding: 0px;
		font-size: 12pt;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: #000;
	}
	&.dark-d {
		label {
			color: #fff;
		}
		textarea {
			color: #fff;
		}
		.bottom-line {
			background: transparent;
		}
		&:focus-within {
			label {
				max-width: 153%;
				transform: translateY(-26px) translateX(0px) scale(0.7, 0.7);
			}
			.bottom-line {
				background: transparent;
			}
		}
	}
}
</style>
