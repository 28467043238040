<template>
	<div class="d-chip">
		<i class="fal" :class="prependIcon" v-if="prependIcon != ''" style="margin-right: 7px"></i
		><slot></slot>
		<i class="fal" :class="appendIcon" v-if="appendIcon != ''" style="margin-left: 7px"></i>
	</div>
</template>
<script>
export default {
	name: "imdex-chip",
	props: {
		prependIcon: {
			type: String,
			default: ""
		},
		appendIcon: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			expanded: null
		};
	},
	methods: {
		expand(i) {
			if (i == this.expanded) {
				this.expanded = null;
			} else {
				this.expanded = i;
			}
		}
	}
};
</script>
<style scoped lang="scss">
.d-chip {
	margin-right: 10px;
	white-space: nowrap;
	padding: 5px 15px;
	border-radius: 100px;
	background: $ww-grey;
	font-size: 10pt;
	color: $ww-dark;
}
</style>
