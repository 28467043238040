<template>
	<div v-show="tempVal" class="d-dialog animated-fast fadeIn" :class="{ fadeOut: closing }">
		<div ref="overlay" class="d-overlay"></div>
		<transition enter-active-class="zoomIn2" leave-active-class="fadeOutDown">
			<div ref="inner" v-if="value" class="d-inner animated-fast">
				<slot></slot>
			</div>
		</transition>
	</div>
</template>
<script>
export default {
	name: "imdex-dialog",
	props: {
		error: {
			type: Boolean,
			default: false
		},
		dismissable: {
			type: Boolean,
			default: true
		},
		width: {
			type: String,
			default: "300"
		},
		value: {
			type: Boolean,
			default: false
		}
	},
	beforeUnmount() {
		window.removeEventListener("click", this.clickEvent);
	},
	mounted() {
		this.tempVal = false;
	},
	data() {
		return {
			clickEvent: null,
			closing: false,
			tempVal: false
		};
	},
	methods: {},
	computed: {},

	watch: {
		value(val) {
			this.$nextTick(() => {
				if (val == true) {
					this.$refs.inner.style.maxWidth = this.width + "px";
					this.tempVal = val;
					setTimeout(() => {
						this.$refs.overlay.addEventListener(
							"click",
							(this.clickEvent = () => {
								if (this.dismissable) {
									this.$emit("update:value", false);
								}
							})
						);
					}, 100);
				} else {
					this.closing = true;
					this.$refs.overlay.removeEventListener("click", this.clickEvent);
					setTimeout(() => {
						this.closing = false;
						this.tempVal = val;
					}, 300);
				}
			});
		}
	}
};
</script>
<style lang="scss">
.d-dialog {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 100000;
	width: 100%;
	left: 0;
	top: 0;
	height: 100vh;
	.d-overlay {
		background: #00000090;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}
	.d-inner {
		position: relative;
		width: 100%;
		padding:15px;
		z-index: 2;
	}
}
.dialog-actions {
	display: flex;
	margin-top: 32px;
	gap: 10px;
	justify-content: flex-end;
}
.dialog-title {
	font-size: 15pt;
	font-weight: bold;
	color: $ww-dark;
	i {
		color: $ww-highlight;
		margin-right: 15px;
	}
}
.dialog-body {
	color: $ww-dark-grey;
	font-size: 12pt;
	margin-top: 25px;
	line-height: 1.8em;
}
.dialog-bottom {
	background: $ww-highlight;
	position: absolute;
	height: 4px;
	width: 100%;
	bottom: 0;
	left: 0;
}
.dialog-card {
	background: #fff;
	border-radius: 3px;
	overflow: hidden;
	padding: 24px;
	&.dialog-error {
		.dialog-title {
			i {
				color: $ww-error !important;
			}
		}
		.dialog-bottom {
			background: $ww-error;
		}
	}
	&.warning {
		.dialog-title {
			i {
				color: $ww-warning !important;
			}
		}
		.dialog-bottom {
			background: $ww-warning;
		}
	}
	&.success {
		.dialog-title {
			i {
				color: $ww-success !important;
			}
		}
		.dialog-bottom {
			background: $ww-success;
		}
	}
}
</style>
