<template>
  <div class="login">
    <d-dialog v-model:value="showSignUp" width="500">
      <div class="dialog">
        <div class="dialog-top">
          <div class="dialog-title">Sign Up</div>
          <div class="dialog-close">
            <d-btn icon mini @click="showSignUp = false"><i class="fal fa-times"></i></d-btn>
          </div>
        </div>
        <div class="dialog-body">
          <h2>Sign up for Well Whisker!</h2>
          <form class="sign-up-form" @click.prevent>
            <div class="row">
              <d-text-field :disabled="signUpLoading" label="Name" v-model:value="signUpName"></d-text-field>
            </div>
            <d-text-field :disabled="signUpLoading" label="Email" v-model:value="signUpEmail"></d-text-field>
            <d-text-field :disabled="signUpLoading" label="Password" type="password" v-model:value="signUpPassword"></d-text-field>
            <div class="form-error">
            <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
                <div v-if="signUpErrorMessage != ''" class="form-error-message animated-fast">
                  {{ signUpErrorMessage }}
                </div>
            </transition>
          </div>
          </form>
          
          
          
        </div>
        <div class="dialog-actions">
          <div class="dialog-submit">
            <d-btn text @click="showSignUp = false">Cancel</d-btn>
          </div>
          <div class="dialog-submit">
            <d-btn @click="submitSignUp" :loading="signUpLoading">Sign Up</d-btn>
          </div>
        </div>
      </div>
    </d-dialog>
    <div>
     
      <form @click.prevent class="form">
        <img src="@/assets/SVG/cats.svg" />
        <d-text-field :disabled="isLoading" style="width:100%;" v-model:value="email" label="Email"></d-text-field>
      <d-text-field :disabled="isLoading"  style="width:100%;" v-model:value="password" type="password" label="Password"></d-text-field>
        
        <div style="width:100%;">
          <d-checkbox v-model:value="check" label="Remember me"></d-checkbox>
        </div>
        
       
        <d-btn @click="submitLogin"  :loading="isLoading" block>Sign In</d-btn>
        <d-btn @click="showSignUp = true" text mini>Sign Up</d-btn>
        <div class="form-error">
            <transition enter-active-class="fadeInUp" leave-active-class="fadeOutDown">
                <div v-if="errorMessage != ''" class="form-error-message animated-fast">
                  {{ errorMessage }}
                </div>
            </transition>
          </div>
        
      </form>
    <div class="bottom">Copyright 2024. All rights reserved.</div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import { useApiStore } from '@/store/apiStore';
import { useAuthStore } from '@/store/authStore';
export default defineComponent({
  name: 'Login',
  setup(){
    const api = useApiStore();
    const authStore = useAuthStore();
    return { api, authStore };
  },
  data(){
    return {
      check:false,
      email:'',
      select:'hello',
      textarea:'',
      password:'',
      showSignUp:false,
      signUpEmail:'',
      signUpName:'',
      signUpPassword:'',
      signUpErrorMessage:'',
      signUpLoading:false,
      isLoading:false,
      errorMessage:''
      
    }
  },
  watch:{
    showSignUp(){
      this.signUpErrorMessage = '';
      this.signUpEmail = '';
      this.signUpName = '';
      this.signUpPassword = '';
    }
  },
  methods:{
    async submitLogin():Promise<any> {
      this.isLoading = true;
      this.errorMessage = '';
        try{
          await this.authStore.login({email:this.email, password:this.password})
          this.$router.push('/dashboard');
        }catch(e:any){
          this.isLoading = false;
          this.errorMessage = e.response.data.error;
        }
    },
    async submitSignUp():Promise<any> {
      this.signUpLoading = true;
      this.signUpErrorMessage = '';
        let post = {
          email:this.signUpEmail,
          password:this.signUpPassword,
          name:this.signUpName
        }
        try{
          await this.api.post({endpoint:'/api/auth/signup', post})
          await this.authStore.login({email:this.signUpEmail, password:this.signUpPassword})
          this.$router.push('/dashboard');
        }catch(e:any){
          this.signUpLoading = false;
          this.signUpErrorMessage = e.response.data.error;
        }
        
      
    }
  },
  components: {

  }
});
</script>
<style scoped lang="scss">
.form{
  max-width:500px;
  display:flex;
  flex-direction: column;
  align-items: center;
  gap:25px;
  img{
    max-width:200px;
  }
}
.description{
  i{
    font-size:25pt;
    color:$light-grey-8;
    transition: all .3s ease;
    cursor: pointer;
    &:hover{
      color:darken($light-grey-8, 50%);
      transform:translateY(10px);
    }
  }
}
.login{
  height:100%;
  background:$light-grey-3;
  text-align: center;
  display:flex;
  align-items:center;
  justify-content: center;
}
.bottom{
  position: fixed;
  bottom:15px;
  color:$ww-text;
  font-size:9pt;
  left:50%;
  transform:translateX(-50%);
}
.sign-up-form{
  padding:0px 25px;
  display:flex;
  flex-direction: column;
  gap:25px;
}
.form-error{
  height:35px;
  .form-error-message{
    height:35px;
    background:$ww-text;
    border-radius: 5px;
    display:flex;
    align-items: center;
    justify-content: center;
    color:#fff;
    font-size:9pt;
    padding:0px 15px;
  }
}
</style>
