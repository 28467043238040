<template>
	<div @click="toggle" class="radio-btn" :class="{ 'disabled-d': disabled, error: error }">
		<input
			ref="radio"
			:style="`color:${color};`"
			@change="toggle"
			name="radio-1"
			:value="label"
			:checked="$parent.value == value"
			type="radio"
		/>
		<div class="radio-icon">
			<div v-if="val == value" class="filled"></div>
		</div>
		<label for="">{{ label }}</label>
	</div>
</template>
<script>
export default {
	name: "imdex-radio",
	props: {
		color: {
			type: String,
			default: "#1d4562"
		},
		value: {
			type: [Boolean, String],
			default: true
		},
		val: {
			type: [Boolean, String],
			default: true
		},
		label: {
			type: String,
			default: ""
		},
		disabled: {
			type: Boolean,
			default: false
		},
		error: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		toggle() {
			if (typeof this.value == "string") {
				this.$emit("update:value", this.val);
				this.$emit("input", this.val);
			} else {
				this.$emit("update:value", !this.value);
				this.$emit("input", !this.value);
			}
		}
	}
};
</script>
<style lang="scss">
.radio-btn {
	cursor: pointer;
	input {
		opacity: 0;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
	}
	align-items: center;
	.input {
		margin-right: 10px;
	}
	label {
		padding: 10px;
		display: inline-block;
		cursor: pointer;
		user-select: none;
		white-space: nowrap;
		color: $ww-dark-grey;
	}
	display: flex;
	position: relative;
	&:active {
		.radio-icon {
			&::before {
				opacity: 1;
			}
		}
	}
	.radio-icon {
		border: solid 2px $ww-dark-grey;
		border-radius: 100px;
		height: 20px;
		width: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&::before {
			content: "";
			height: 41px;
			width: 41px;
			top: 50%;
			transform: translateY(-50%);
			border-radius: 100px;
			background: $ww-dark-grey-trans;
			z-index: 1;
			position: absolute;
			transition: all 0.2s ease;
			opacity: 0;
		}

		.filled {
			height: 50%;
			width: 50%;
			border-radius: 100px;
			background: $ww-dark-grey;
		}
	}
	&.error {
		.radio-icon {
			.filled {
				background: $ww-error !important;
			}
			border-color: $ww-error !important;
		}
		label {
			color: $ww-error !important;
		}
		.assistive-text {
			color: $ww-error;
		}
	}
	&.disabled-d {
		background: transparent;
		pointer-events: none;
		.radio-icon {
			.filled {
				background: $ww-tertiary-light !important;
			}
			border-color: $ww-tertiary-light !important;
		}
		label {
			color: $ww-tertiary-light !important;
			pointer-events: none;
		}
	}
}
</style>
