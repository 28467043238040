<template>
    <div class="chart-container">
        <d-dialog width="300" v-model:value="addWeightDialog">
            <div class="dialog">
                <div class="dialog-top">
                    <div class="dialog-title">Add Weight</div>
                    <div class="dialog-close">
                        <d-btn @click="addWeightDialog = false" icon mini><i class="fal fa-times"></i></d-btn>
                    </div>
                </div>
                <div class="dialog-body">
                    <div class="weight-entry">
                        <d-text-field type="number" v-model:value="currentWeight" label="Current Weight"></d-text-field> 
                        <span>lbs</span>
                    </div>
                    
                </div>
                <div class="dialog-actions">
                    <div class="dialog-cancel">
                        <d-btn small text @click="addWeightDialog = false">Cancel</d-btn>
                    </div>
                    <div class="dialog-confirm">
                        <d-btn small @click="submitWeight">Submit</d-btn>
                    </div>
                    
                </div>
            </div>
        </d-dialog>
        <div class="chart-top">
            <div class="chart-title">Timeline <d-select v-model:value="selectedTimelineCategory" small label="Category" :items="timelineOptions"></d-select></div>
            
        </div>

        <!-- <div style="height:calc(100% - 39px);">
            <LineChart style="height:100%;" ref="timeline" :options="lineOptions" :chartData="lineData" />
        </div> -->
        <div class="timeline">
            <div class="timeline-month" v-for="(item, i) in months" :key="i">
                <div class="timeline-month-label">{{ item.month }}</div>
                <div class="timeline-day-container">
                    <div :class="{'hidden':day.number == -1, 'after': moment().year(item.year).month(item.month).date(day.number).isAfter(moment()), 'today':moment().format('MMM D').toLowerCase() == item.month.toLowerCase() + ' ' + day.number}" class="timeline-day" v-for="(day,d) in item.days" :key="d">
                        
                            <span>{{ day.number }}</span>
                        
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, computed, watch } from 'vue';
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import moment from 'moment-timezone';
import 'chartjs-adapter-moment';
Chart.register(...registerables)
Chart.defaults.font.family = "Quicksand";
export default defineComponent({
    name: 'TimeLine',
    components: {
        LineChart
    },
    props: ['pet'],
    setup(props) {
        let addWeightDialog = ref(false);
        const currentWeight = ref('');
        const timeline = ref();
        const weights = reactive([
            {
                'name': 'Khaleesi',
                'weight': 0.1,
                date: moment('09/10/2023').toDate()
            },
            {
                'name': 'Khaleesi',
                'weight': 0.2,
                date: moment('10/12/2023').toDate()
            },
            {
                'name': 'Khaleesi',
                'weight': 0.0,
                date: moment('11/16/2023').toDate()
            },
            {
                'name': 'Khaleesi',
                'weight': 0.1,
                date: moment('12/13/2023').toDate()
            },

        ]);

        const minWeight = computed(() => weights.map((item) => item.weight).sort((a, b) => a - b)[0] as any);
        const maxWeight = computed(() => weights.map((item) => item.weight).sort((a, b) => a - b)[weights.length - 1] as any);

        watch(minWeight, (v) => {
            
            timeline.value.chartInstance.options.scales.y.min = ((v as any) - 1);
            timeline.value.chartInstance.options.scales.y.max = (maxWeight.value as any) + 1;
            timeline.value.update();
        })

        watch(maxWeight, (v) => {
            timeline.value.chartInstance.options.scales.y.max = ((v as any) + 1);
            timeline.value.chartInstance.options.scales.y.min = Number(minWeight.value) - 3;
            timeline.value.update();
        })

        const weightCheck = computed(() => {
            let currentWeight = weights[weights.length - 1].weight;
            if (currentWeight <= 11 && currentWeight >= 9) {
                return 'good'
            }
            if (currentWeight > 13) {
                return 'obese'
            }
            if (currentWeight < 7) {
                return 'thin'
            }
            if (currentWeight < 9) {
                return 'underweight'
            }

            if (currentWeight > 11) {
                return 'overweight'
            }

        })
        const submitWeight = () => {
            weights.push({
                name: props.pet,
                weight: Number(currentWeight.value),
                date: new Date()
            })
            addWeightDialog.value = false;
            timeline.value.chartInstance.data.datasets[0].data.splice(0, 1);
            timeline.value.chartInstance.data.labels.splice(0, 1)
            timeline.value.update();
            timeline.value.chartInstance.data.labels.push(moment().format('MMM DD, YYYY'))

            timeline.value.chartInstance.data.datasets[0].data.push(Number(currentWeight.value))


            timeline.value.update();

        }
        const lineData = {
            labels: weights.map((item) => moment(item.date).format('MMM DD, YYYY')),
            datasets: [
                {
                    labels: weights.map((item) => item.weight + 'lbs'),
                    data: weights.map((item) => item.weight),
                    pointRadius: 3,
                    pointHoverRadius: 5,

                    borderColor: (context: any) => {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;
                        const followFill = ctx.createLinearGradient(0, 300, 0, 100);
                        followFill.addColorStop(0, "#b14c6880");
                        followFill.addColorStop(1, "#64394580");
                        return followFill;
                    },
                    pointBackgroundColor: (context: any) => {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;
                        const followFill = ctx.createLinearGradient(0, 300, 0, 100);
                        followFill.addColorStop(0, "#b14c6880");
                        followFill.addColorStop(1, "#64394580");
                        return followFill;
                    },
                    backgroundColor: (context: any) => {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;
                        const followFillHover = ctx.createLinearGradient(0, 300, 0, 100);
                        followFillHover.addColorStop(0, "#b14c6810");
                        followFillHover.addColorStop(1, "#64394510");
                        return followFillHover;
                    },
                    hoverBackgroundColor: (context: any) => {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;
                        const followFillHover = ctx.createLinearGradient(0, 300, 0, 100);
                        followFillHover.addColorStop(0, "#b14c6810");
                        followFillHover.addColorStop(1, "#64394510");
                        return followFillHover;
                    }
                },
            ]
        }

        const lineOptions = {
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    intersect: false,
                    backgroundColor: "#643945",
                    borderRadius: 0,
                    titleFontSize: 11,
                    titleFontFamily: "Quicksand",
                    titleFontColor: "#808080",
                    bodyFontColor: "#fff",
                    bodyFontSize: 12,
                    bodyFontFamily: "Quicksand",
                    displayColors: false
                }
            },
            showLine: true,
            fill: false,
            tension: .4,
            scales: {
                y: {
                    min: 0,
                    max: 1,
                    ticks: {
                        font: {
                            size: 12,
                        },
                        fontColor: "#00000080",
                        fontFamily: "Quicksand"
                    },
                    grid: {
                        color: "rgba(0, 0, 0, .05)",
                        zeroLineColor: "rgba(0,0,0,0.1)"
                    }
                },
                x: {
                    type: "time",
                    // time: {
                    //   unit: "month",
                    //   min: new Date("2019-02-10"),
                    //   max: new Date()
                    // },
                    stacked: false,
                    ticks: {
                        size: 10,
                        fontFamily: "Quicksand",
                        autoSkip: true,
                        beginAtZero: true,
                        maxTicksLimit: 10,
                        fontColor: "#00000080",
                        maxRotation: 0,
                        minRotation: 0
                    },
                    grid: {
                        color: "rgba(0, 0, 0, 0)",
                        zeroLineColor: "rgba(0,0,0,0.1)"
                    },
                    categorySpacing: 10,
                    barPercentage: 0.7
                },


            },
        }

        return { lineData, lineOptions, weights, addWeightDialog, currentWeight, submitWeight, timeline, weightCheck, minWeight, maxWeight };
    },
    data(){
        return {
            moment,
            selectedTimelineCategory:''
        }
    },
    computed:{
        timelineOptions(){
            return [
                {
                    text:'Weight',
                    value:'weight'
                }
            ]
        },
        months(){
            let months = moment.monthsShort();
            let currentMonthIdx = months.findIndex((item)=>item == moment().format('MMM'))
            console.log(currentMonthIdx);
            let today = moment();
            let currentMonth = moment().format('MMM');
            let newMonthArr = [];
            for(let i = 0; i < 12; i++){
                newMonthArr.push({
                    month: moment().month(currentMonthIdx).add(-i, 'month').format('MMM'),
                    year: Number(moment().month(currentMonthIdx).add(-i, 'month').format('YYYY'))
                })
            }
            newMonthArr = newMonthArr.reverse();
            let monthArr = [];
            for(let i = 0; i < newMonthArr.length; i++){
                let m = moment().year(newMonthArr[i].year).month(newMonthArr[i].month).daysInMonth();
                let skip = moment().year(newMonthArr[i].year).month(newMonthArr[i].month).date(1).isoWeekday();
                
                let days:any = [...Array.from(Array(skip).keys()).map((item)=>({number:-1}))];
                for(let p = 0; p<m;p++){
                    days.push(
                        {
                            number:p+1
                        }
                    )
                }
                monthArr.push({
                    month:newMonthArr[i].month,
                    year:newMonthArr[i].year,
                    days
                })
            }
            return monthArr;
        }
    }
})
</script>
<style lang="scss">
.timeline{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap:25px;
    overflow: hidden;
    overflow-x:hidden;
    padding-bottom:15px;
    margin-top:15px;
    margin-bottom:6px;
    &:hover{
        margin-bottom:0px;
        overflow-x:auto;
    }
    @include media-query($small){
        overflow-x:auto;
    }
    .timeline-month-label{
        font-size:10pt;
        color:$ww-text;
        user-select: none;
        margin-bottom:5px;
    }
    .timeline-day-container{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
        gap:5px;
        .timeline-day{
            height:15px;
            
            background:lighten($ww-accent, 40%);
            transition:all .1s ease;
            cursor: pointer;
            display:flex;
            align-items: center;
            justify-content: center;
            span{
                color:#fff;
                opacity: 0;
                font-size:8pt;
                font-weight: bold;
                transition:all .3s ease;
                transform:translateY(-1px)
            }
            &.today{
                box-shadow: 0px 0px 0px 2px $ww-accent;
            }
            &.after{
                background:#efedee
            }
            &:hover{
                span{
                    opacity: 1;
                }
                transform:scale(2,2) translateY(-5px);
                z-index: 100;
                background: $ww-accent;
                &.after{
                    background:#6e676b
                }
            }
            border-radius: 3px;
            width:15px;
            &.hidden{
                background: transparent;
            }
        }

    }
}
</style>