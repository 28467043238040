<template>
	<div class="d-slider">
		<input type="number" ref="input" :value="value" />
		<div ref="bar" class="d-slider-bar"></div>
		<label>{{ label }}</label>
		<div v-if="rangeLabels" class="d-slider-labels">
			<div class="d-slider-label-min">{{ minLabel }}</div>
			<div class="d-slider-label-max">{{ maxLabel }}</div>
		</div>

		<div
			ref="handle"
			@mousedown="startDrag"
			class="d-slider-handle"
			:class="{ 'marker-disabled': !labelOnChange }"
		>
			<div class="d-slider-value">{{ value }}</div>
			<i class="fa fa-map-marker"></i>
		</div>
	</div>
</template>
<script>
import _ from "lodash";
import { defineComponent } from "vue";
export default defineComponent({
	name: "imdex-slider",
	props: {
		rangeLabels: {
			type: Boolean,
			default: true
		},
		labelOnChange: {
			type: Boolean,
			default: true
		},
		label: {
			type: String,
			default: ""
		},
		value: {
			type: Number,
			default: 0
		},
		min: {
			type: Number,
			default: 0
		},
		max: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			mousedownEvent: null,
			mousemoveEvent: null,
			mouseupEvent: null
		};
	},
	mounted() {
		this.$refs.handle.style.left =
			((this.value - this.min) / (this.max - this.min)) * 100 + "%";
	},
	methods: {
		startDrag(e) {
			e.preventDefault();
			const startX = this.$refs.bar.getBoundingClientRect();
			const updateThrottle = _.throttle((val) => {
				this.$emit("change", val);
			}, 50);
			window.addEventListener(
				"mousemove",
				(this.mousemoveEvent = (e) => {
					const xx = e.pageX - startX.left;
					const p = xx / startX.width;
					if (p <= 1 && p >= 0) {
						this.$refs.handle.style.left = p * 100 + "%";
						//  this.$emit('update:value', Math.round((this.max - this.min) * p) + this.min);
						updateThrottle(Math.round((this.max - this.min) * p) + this.min);
					}
					if (p > 1) {
						this.$refs.handle.style.left = 100 + "%";
						// this.$emit('update:value', Math.round((this.max - this.min) * 1) + this.min);
						updateThrottle(Math.round((this.max - this.min) * 1) + this.min);
					}
					if (p < 0) {
						this.$refs.handle.style.left = 0 + "%";
						updateThrottle(Math.round(this.min * 1));
					}
				})
			);
			window.addEventListener(
				"mouseup",
				(this.mouseupEvent = () => {
					window.removeEventListener("mousemove", this.mousemoveEvent);
					window.removeEventListener("mouseup", this.mouseupEvent);
				})
			);
		}
	},
	computed: {
		minLabel() {
			return this.min;
		},
		maxLabel() {
			return this.max;
		}
	},
	watch: {
		value(e) {
			if (e >= this.min && e <= this.max) {
				const p = (e - this.min) / (this.max - this.min);
				if (p <= 1 && p >= 0) {
					this.$refs.handle.style.left = p * 100 + "%";
				}
				if (p > 1) {
					this.$refs.handle.style.left = 100 + "%";
				}
				if (p < 0) {
					this.$refs.handle.style.left = 0 + "%";
				}
			}
		}
	}
});
</script>
<style scoped lang="scss">
.d-slider {
	width: 100%;
	position: relative;
	label {
		position: absolute;
		top: 0;
		left: 0;
		font-size: 10pt;
		color: $ww-primary;
	}
	input {
		position: absolute;
		opacity: 0;
		pointer-events: none;
	}
	.d-slider-bar {
		height: 4px;
		background: $ww-grey;
		width: 100%;
		margin-top: 0px;
	}
	.d-slider-handle {
		height: 15px;
		width: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: translateX(-50%) translateY(calc(-50% + 2px));
		.d-slider-value {
			position: absolute;
			color: #fff;
			z-index: 10;
			top: -20px;
			opacity: 0;
		}
		i {
			color: transparent;
			font-size: 25pt;
			transform: translateY(-15px);
		}
		cursor: pointer;
		position: absolute;
		top: 0;

		border-radius: 20px;
		background: $ww-secondary;
		transition: transform 0.3s ease;
		&.marker-disabled {
			.d-slider-value {
				display: none;
			}
		}
		&:hover {
			background: transparent;
			.d-slider-value {
				opacity: 1;
			}
			i {
				color: $ww-secondary;
				font-size: 35pt;
				transform: translateY(-15px);
			}
			&.marker-disabled {
				background: $ww-secondary !important;
				i {
					display: none;
				}
				.d-slider-value {
					opacity: 1 0 !important;
				}
			}
		}
		&:active {
			background: transparent;
			cursor: none !important;
			.d-slider-value {
				opacity: 1;
			}
			i {
				color: $ww-secondary;
				font-size: 35pt;
				transform: translateY(-15px);
			}
			&.marker-disabled {
				background: $ww-secondary !important;
				i {
					display: none;
				}
				.d-slider-value {
					opacity: 1 0 !important;
				}
			}
		}
	}
	.d-slider-labels {
		display: flex;
		align-items: center;
		padding: 10px 0px;
		width: 100%;
		font-size: 10pt;
		color: $ww-dark-grey;
		.d-slider-label-max {
			margin-left: auto;
		}
	}
}
</style>
