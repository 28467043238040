<template>
	<div class="button-group">
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: "button-toggle",
	props: {
		value: {
			type: String,
			default: "0"
		},
		dark: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {};
	},
	mounted() {
		this.init();
	},
	beforeUnmount() {
		this.removeEventListeners();
	},
	methods: {
		init() {
			this.$nextTick(() => {
				const slots = this.$slots.default();
				for (const i in slots) {
					this.$emit("update:value", slots[i].elm.value);
					if (slots[i].elm.value == this.value) {
						slots[i].elm.style.boxShadow = !this.dark
							? "#00345C 0px 0px 100px inset"
							: "#ffffff57 0px 0px 100px inset";
						slots[i].elm.style.color = !this.dark ? "#fff" : "unset";
					} else {
						slots[i].elm.style.boxShadow = "unset";
						slots[i].elm.style.color = null;
					}
					slots[i].elm.addEventListener(
						"click",
						(this[slots[i].elm.value] = () => {
							this.$emit("update:value", slots[i].elm.value);
						})
					);
				}
			});
		},
		removeEventListeners() {
			const slots = this.$slots.default();
			for (const i in slots) {
				slots[i].elm.removeEventListener("click", this[slots[i].elm.value]);
			}
		}
	},
	watch: {
		value(v) {
			this.$nextTick(() => {
				const slots = this.$slots.default();
				for (const i in slots) {
					if (slots[i].elm.value == v) {
						slots[i].elm.style.boxShadow = !this.dark
							? "#00345C 0px 0px 100px inset"
							: "#ffffff57 0px 0px 100px inset";
						slots[i].elm.style.color = !this.dark ? "#fff" : "unset";
					} else {
						slots[i].elm.style.boxShadow = "unset";
						slots[i].elm.style.color = null;
					}
				}
			});
		}
	}
};
</script>
<style lang="scss">
.button-group {
	padding: 15px;
	display: flex;
	gap: 5px;
}
</style>
