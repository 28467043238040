<template>
	<div v-show="!hidden" class="d-tab-item">
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: "imdex-tab-item",
	props: {
		hidden: {
			type: Boolean,
			default: true
		}
	}
};
</script>
<style scoped lang="scss">
.d-tab-item {
	transition: all 0.3s ease;
	opacity: 1;
	width: 100%;
	top: 0;
	left: 0;

	&.hidden {
		opacity: 0;
		width: 100%;
		position: absolute;
		pointer-events: none;
		transform: translateX(100px);
	}
}
</style>
