<template>
    <div ref="dash" class="page">
        <div class="page-top">
            <div class="page-title">
              <i  class="fad fa-calendar"></i>  {{todayDate}}
            </div>
        </div>
        <d-dialog width="600" v-model:value="addPetDialog">
            <AddPetDialog @close="addPetDialog = false"  />
        </d-dialog>
        
        <transition enter-active-class="fadeIn" leave-active-class="fadeOut">
            <div v-if="showFill" class="page-fill animated-fast"> 
                <d-btn mini @click="closeFill"><i  class="fal fa-times"></i></d-btn>
                <PetDash :pet="activePet"></PetDash>
            </div>
        </transition>
        <div class="page-section pets">
            <div class="section-title">
                <i class="fad fa-paw"></i> Pets
                <d-btn @click="addPetDialog = true" rounded white small elevated prependIcon="fal fa-plus"> Add Pet</d-btn>
            </div>
            <div class="section-body">
                <div v-for="(pet, i) in pets" :key="i" @click="activatePet(pet.name)" class="pet-container" :ref="pet.name" :class="{'active':activePet == pet.name}">
                    <img :src="pet.img"/>
                    <div class="pet-info"> <i class="fad fa-cat"></i> {{pet.name}}</div>
                </div>
                
            </div>
        </div>
        
        
    </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import PetDash from '../components/PetDash/index.vue';
import AddPetDialog from '../components/AddPet.vue';
import moment from 'moment-timezone';
export default defineComponent({
    name:'Dashboard',
    components:{
        PetDash,
        AddPetDialog
    },
    data(){
        return {
            todayDate:moment().format('MMM DD hh:mm A') as any,
            dateInterval:null as any,
            activePet:'' as any,
            showFill:false,
            addPetDialog:false,
            pets:[
                {
                    name:'Khaleesi',
                    type:'cat',
                    img:require('@/assets/img/kk.png')
                },
                {
                    name:'Chota',
                    type:'cat',
                    img:require('@/assets/img/cho.png')
                }
            ]
        }
    },
    mounted(){
        // this.dateInterval = setInterval(()=>{
        //     this.todayDate = moment().format('MMM DD hh:mm A')
        // },1000)
    },
    methods:{
        closeFill():any{
            let circleEl: any = document.getElementsByClassName('active-pet-circle');
            this.showFill = false;
            circleEl[0].classList.remove('active');
            let petRef: any = (this.$refs[this.activePet] as any)[0];
            let {left, top, width, height} = petRef.getBoundingClientRect();
            let {left:left2, top:top2, width:width2, height:height2} = (this.$refs.dash as any).getBoundingClientRect();
            this.activePet = '';
            circleEl[0].style.left = left - left2 + width/2 - 0 + 'px';
            circleEl[0].style.top = top - top2 + height/2 - 0 + 'px';
            setTimeout(()=>{
                (this.$refs.dash as any).removeChild(circleEl[0]);
                
            },400)
        },
        activatePet(pet:string):any{
           
            let petRef: any = (this.$refs[pet] as any)[0];
            console.log(petRef);
            let {left, top, width, height} = petRef.getBoundingClientRect();
            let {innerHeight, innerWidth}:{innerHeight:number, innerWidth:number} = window;
            let {left:left2, top:top2, width:width2, height:height2} = (this.$refs.dash as any).getBoundingClientRect();

            let el = document.createElement('div');
            (this.$refs['dash'] as any).appendChild(el);
            el.classList.add('active-pet-circle')
            el.style.left = left - left2 + width/2 - 0 + 'px';
            el.style.top = top - top2 + height/2 - 0 + 'px';
            
            setTimeout(()=>{
                el.classList.add('active');
                let factor = 1.5;
                if(innerWidth < 800){
                    factor = 2.5
                }
                el.style.left = (width2 / 2) - ((innerWidth * factor)/2) + 'px'
                el.style.top = (height2 / 2)  - ((innerWidth * factor)/2) + 'px'
            },110)
            setTimeout(()=>{
                this.showFill = true;  
            },400)
            this.activePet = pet;
        }
    },
    // beforeUnmount(){
    //     clearInterval(this.dateInterval);
    // }
})

</script>
<style scoped lang="scss">
.page{
    position:relative;
}
.page-title{
    color:$ww-text;
    i{
        color:$ww-accent;
    }
    font-size:24pt;
    @include media-query($small){
        font-size:15pt;
    }
}
.section-title{
    font-size:15pt;
    color:$ww-text;
    display:flex;
    align-items: center;
    gap:15px;
    margin-bottom:15px;
    padding:25px 0px;
    i{
        color:$ww-accent;
    }
}
.page-section{
    background:$light-grey-3;
}
.pets{
    .section-title{
        @include media-query($small){
            display:flex;
            padding-top:0;
            padding-bottom:0;
            align-items: center;
            .btn-b{
                margin-left:auto;
            }
        }
    }
    .section-body{
        display:flex;
        align-items: center;
        gap:25px;
    }
}
.pet-container{
    border:solid 6px #fff;
    background:#fff;
    box-shadow: 0px 12px 15px -10px #b2a3a7;
    border-radius: 100px;
    width:200px;
    height:200px;
    @include media-query($small){
        width:100px;
        height:100px;
        border:solid 3px #fff;
    }
    
    overflow: hidden;
    position: relative;
    transition:all .3s ease;
    
    &::before{
        content:'';
        height:0%;
        width:0%;
        background:transparent;
        position:absolute;
        top:50%;
        left:50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 50%;
        z-index: 10;
        transition:all .5s;
    }
    &.active{
        &::before{
            height:100%;
            width:100%;
            background:#fff;
            content:'';
            transform: translateX(-50%) translateY(-50%) scale(1,1);
        }
    }
    &:hover{
        transform:scale(1.04,1.04);
        box-shadow: 0px 12px 15px -10px #b2a3a7, 0px 0px 0px 4px #b14c68;
        .pet-info{
            transform: translateY(0);
        }
    }
    cursor: pointer;
    .pet-info{
        position:absolute;
        z-index: 10;
        transition: all .3s ease;
        bottom:0;
        left:0;
        display:flex;
        align-items: center;
        justify-content: center;
        background:#b14c68bf;
        width:100%;
        height:50px;
        color:#fff;
        transform:translateY(100%);
        gap:10px;
        i{
            color:#fff
        }

    }
    img{
        height:100%;
        width:100%;
        object-fit: cover;
    }
}
.page-fill{
    position:absolute;
    padding:25px;
    overflow:auto;
    overflow-x: hidden;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:lighten($ww-accent, 46%);
    @include media-query($small){
        padding:15px;
    }
    z-index: 1000;
}
</style>