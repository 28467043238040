<template>
  <Header />
  <div v-if="$route.path == '/'" class="router-view">
    <router-view/>
  </div>
  <div v-if="$route.path != '/'" class="router-view page-view">
    <div @click="sidebarOpen = !sidebarOpen" class="mobile-menu-btn"><i class="fad fa-bars"></i></div>
    <sidebar :open="sidebarOpen" />
    <div class="stage">
      <router-view/>
    </div>
    <div id="tooltip-container"></div>
  </div>
</template>
<script>
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import {defineComponent, ref} from 'vue';
import { useApiStore } from './store/apiStore';
import jwt_decode from 'jwt-decode';
import { useAuthStore } from './store/authStore';
export default defineComponent({
  name:'App',
  components:{
    Header,
    Sidebar
  },
  mounted(){
    this.api.init();
    if(localStorage.getItem('wellwhisker_token') != null){
      let decoded = jwt_decode(localStorage.getItem('wellwhisker_token'));
      this.authStore.setUser(decoded);
    }
  },
  setup(){
    const sidebarOpen = ref(false);
    const api = useApiStore();
    const authStore = useAuthStore();
    return {sidebarOpen, api, authStore};
  }


})
</script>

<style lang="scss">
.mobile-menu-btn{
  position:fixed;
  height:70px;
  top:0;
  left:0;
  align-items: center;
  justify-content: center;
  padding:0px 20px;
  z-index: 1002;
  display:none;
  @include media-query($small){
    display:flex;
  }
  i{
    color:$ww-text;
    font-size:15pt;
  }
}
.page-view{
  display:flex;
  min-height:100vh;
}
#tooltip-container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000010;
}
.router-view{
  padding-top:70px;
  min-height:100vh;
  height:100vh;
  position:relative;
  
}
.stage{
  padding-left:300px;
  @include media-query($small){
    padding-left:0;
  }
  width:100%;
  min-height: calc(100vh - 70px);
}
</style>
