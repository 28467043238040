<template>
	<div class="d-tab-items">
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: "imdex-tab-items",
	props: {
		value: {
			type: [Number, String]
		}
	},
	data() {
		return {
			tabItems: []
		};
	}
};
</script>
<style lang="scss">
.d-tab-items {
	position: relative;
	width: 100%;
}
</style>
