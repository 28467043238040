<template>
    <div style="flex:1;" class="chart-container">
        <d-dialog width="300" v-model:value="addWeightDialog">
            <div class="dialog">
                <div class="dialog-top">
                    <div class="dialog-title">Add Weight</div>
                    <div class="dialog-close">
                        <d-btn @click="addWeightDialog = false" icon mini><i class="fal fa-times"></i></d-btn>
                    </div>
                </div>
                <div class="dialog-body">
                    <div class="weight-entry">
                        <d-text-field type="number" v-model:value="currentWeight" label="Current Weight"></d-text-field> 
                        <span>lbs</span>
                    </div>
                    
                </div>
                <div class="dialog-actions">
                    <div class="dialog-cancel">
                        <d-btn small text @click="addWeightDialog = false">Cancel</d-btn>
                    </div>
                    <div class="dialog-confirm">
                        <d-btn small @click="submitWeight">Submit</d-btn>
                    </div>
                    
                </div>
            </div>
        </d-dialog>
        <div class="chart-top">
            <div class="chart-title">Weight <d-btn text icon mini @click="addWeightDialog = !addWeightDialog"><i
                        class=" fal fa-plus"></i></d-btn></div>
            <div class="chart-value">
                <div class="weight" :class="weightCheck">

                    <d-tooltip :nudgeY="-10">
                        <template #activator="on">
                            <i v-on="on" class="fad fa-info-circle"></i>
                        </template>
                        <template #tooltip>
                            <div v-if="weightCheck == 'good'" style="min-width:150px; text-align:center;">
                                Congrats! {{ pet }} is a healtly weight!
                            </div>
                            <div v-if="weightCheck == 'underweight'" style="min-width:150px; text-align:center;">
                                {{ pet }} is a bit on the lighter side!
                            </div>
                            <div v-if="weightCheck == 'overweight'" style="min-width:150px; text-align:center;">
                                {{ pet }} is a bit overweight!
                            </div>
                            <div v-if="weightCheck == 'thin'" style="min-width:150px; text-align:center;">
                                {{ pet }} is too much too thin.
                            </div>
                            <div v-if="weightCheck == 'obese'" style="min-width:150px; text-align:center;">
                                {{ pet }} is a chonk! {{ pet }} needs to go on a diet badly.
                            </div>
                        </template>
                    </d-tooltip>


                    <i v-if="weightCheck == 'good'" class="fal fa-smile-beam"></i>
                    <i v-if="weightCheck == 'overweight' || weightCheck == 'underweight'"
                        class="fal fa-grin-beam-sweat"></i>
                    <i v-if="weightCheck == 'obese' || weightCheck == 'thin'" class="fal fa-frown"></i>
                    {{ weights[weights.length - 1].weight }} lbs
                </div>
            </div>
        </div>

        <div>
            <LineChart ref="weightChart" :options="lineOptions" :chartData="lineData" />
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, computed, watch } from 'vue';
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import moment from 'moment-timezone';
import 'chartjs-adapter-moment';
Chart.register(...registerables)
Chart.defaults.font.family = "Quicksand";
export default defineComponent({
    name: 'weight-chart',
    components: {
        LineChart
    },
    props: ['pet'],
    setup(props) {
        let addWeightDialog = ref(false);
        const currentWeight = ref('');
        const weightChart = ref();
        const weights = reactive([
            {
                'name': 'Khaleesi',
                'weight': 10.1,
                date: moment('09/10/2023').toDate()
            },
            {
                'name': 'Khaleesi',
                'weight': 10.2,
                date: moment('10/12/2023').toDate()
            },
            {
                'name': 'Khaleesi',
                'weight': 10.0,
                date: moment('11/16/2023').toDate()
            },
            {
                'name': 'Khaleesi',
                'weight': 10.1,
                date: moment('12/13/2023').toDate()
            },

        ]);

        const minWeight = computed(() => weights.map((item) => item.weight).sort((a, b) => a - b)[0] as any);
        const maxWeight = computed(() => weights.map((item) => item.weight).sort((a, b) => a - b)[weights.length - 1] as any);

        watch(minWeight, (v) => {
            console.log('min', v);
            weightChart.value.chartInstance.options.scales.y.min = ((v as any) - 1);
            weightChart.value.chartInstance.options.scales.y.max = (maxWeight.value as any) + 1;
            weightChart.value.update();
        })

        watch(maxWeight, (v) => {
            weightChart.value.chartInstance.options.scales.y.max = ((v as any) + 1);
            weightChart.value.chartInstance.options.scales.y.min = Number(minWeight.value) - 3;
            weightChart.value.update();
        })

        const weightCheck = computed(() => {
            let currentWeight = weights[weights.length - 1].weight;
            if (currentWeight <= 11 && currentWeight >= 9) {
                return 'good'
            }
            if (currentWeight > 13) {
                return 'obese'
            }
            if (currentWeight < 7) {
                return 'thin'
            }
            if (currentWeight < 9) {
                return 'underweight'
            }

            if (currentWeight > 11) {
                return 'overweight'
            }

        })
        const submitWeight = () => {
            weights.push({
                name: props.pet,
                weight: Number(currentWeight.value),
                date: new Date()
            })
            addWeightDialog.value = false;
            console.log(weightChart.value.chartInstance);
            weightChart.value.chartInstance.data.datasets[0].data.splice(0, 1);
            weightChart.value.chartInstance.data.labels.splice(0, 1)
            weightChart.value.update();
            weightChart.value.chartInstance.data.labels.push(moment().format('MMM DD, YYYY'))

            weightChart.value.chartInstance.data.datasets[0].data.push(Number(currentWeight.value))


            weightChart.value.update();

        }
        const lineData = {
            labels: weights.map((item) => moment(item.date).format('MMM DD, YYYY')),
            datasets: [
                {
                    labels: weights.map((item) => item.weight + 'lbs'),
                    data: weights.map((item) => item.weight),
                    pointRadius: 3,
                    pointHoverRadius: 5,

                    borderColor: (context: any) => {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;
                        const followFill = ctx.createLinearGradient(0, 300, 0, 100);
                        followFill.addColorStop(0, "#b14c6880");
                        followFill.addColorStop(1, "#64394580");
                        return followFill;
                    },
                    pointBackgroundColor: (context: any) => {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;
                        const followFill = ctx.createLinearGradient(0, 300, 0, 100);
                        followFill.addColorStop(0, "#b14c6880");
                        followFill.addColorStop(1, "#64394580");
                        return followFill;
                    },
                    backgroundColor: (context: any) => {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;
                        const followFillHover = ctx.createLinearGradient(0, 300, 0, 100);
                        followFillHover.addColorStop(0, "#b14c6810");
                        followFillHover.addColorStop(1, "#64394510");
                        return followFillHover;
                    },
                    hoverBackgroundColor: (context: any) => {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;
                        const followFillHover = ctx.createLinearGradient(0, 300, 0, 100);
                        followFillHover.addColorStop(0, "#b14c6810");
                        followFillHover.addColorStop(1, "#64394510");
                        return followFillHover;
                    }
                },
            ]
        }

        const lineOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    intersect: false,
                    backgroundColor: "#643945",
                    borderRadius: 0,
                    titleFontSize: 11,
                    titleFontFamily: "Quicksand",
                    titleFontColor: "#808080",
                    bodyFontColor: "#fff",
                    bodyFontSize: 12,
                    bodyFontFamily: "Quicksand",
                    displayColors: false
                }
            },
            showLine: true,
            fill: true,
            tension: .4,
            scales: {
                y: {
                    min: (Number(minWeight.value) - 1),
                    max: (Number(maxWeight.value) + 1),
                    ticks: {
                        font: {
                            size: 12,
                        },
                        fontColor: "#00000080",
                        fontFamily: "Quicksand"
                    },
                    grid: {
                        color: "rgba(0, 0, 0, .05)",
                        zeroLineColor: "rgba(0,0,0,0.1)"
                    }
                },
                x: {
                    type: "time",
                    // time: {
                    //   unit: "month",
                    //   min: new Date("2019-02-10"),
                    //   max: new Date()
                    // },
                    stacked: false,
                    ticks: {
                        size: 10,
                        fontFamily: "Quicksand",
                        autoSkip: true,
                        beginAtZero: true,
                        maxTicksLimit: 10,
                        fontColor: "#00000080",
                        maxRotation: 0,
                        minRotation: 0
                    },
                    grid: {
                        color: "rgba(0, 0, 0, 0)",
                        zeroLineColor: "rgba(0,0,0,0.1)"
                    },
                    categorySpacing: 10,
                    barPercentage: 0.7
                },


            },
        }

        return { lineData, lineOptions, weights, addWeightDialog, currentWeight, submitWeight, weightChart, weightCheck, minWeight, maxWeight };
    },
})
</script>
<style lang="scss">

</style>