<template>
	<div
		class="d-select"
		ref="container"
		:class="{
			'dark-d': dark,
			'small-d': small,
			mini: mini,
			error: error,
			'd-disabled': disabled
		}"
	>
		<fieldset ref="fieldset">
			<legend :style="`width:${labelWidth}px`" ref="legend"><span></span></legend>
		</fieldset>
		<label ref="label" :class="{ filled: value != '', icon: leadingIcon != '' }">{{
			label
		}}</label>
		<div class="d-select-inner">
			<input
				ref="input"
				:value="
					objectOptions
						? value == ''
							? ''
							: items.findIndex((item) => item.value == value) != -1
							? items[items.findIndex((item) => item.value == value)].text
							: ''
						: value
				"
				readonly
				class="select-input-input"
				:class="searchable && menuOpen ? 'hidden' : ''"
				type="text"
			/>
			<input
				ref="searchableText"
				type="text"
				class="searchable-input"
				v-model="searchTerm"
				v-show="searchable && menuOpen"
			/>
			<select
				ref="selectInput"
				class="select-input"
				@input="
					$emit(
						'update:value',
						objectOptions ? $event.target.value.value : $event.target.value
					)
				"
				:value="objectOptions ? value.value : value"
			>
				<option
					v-for="(item, i) in filteredItems"
					:key="i"
					:value="objectOptions ? item.text : item"
				>
					{{ objectOptions ? item.text : item }}
				</option>
			</select>
			<i
				style="padding: 0px 10px; pointer-events: none"
				:class="{ 'dark-d': dark }"
				class="fal fa-chevron-down"
			></i>
			<div
				ref="menu"
				v-show="menuOpen"
				class="d-select-menu"
				:class="{
					'dark-d': dark,
					'small-d': small,
					mini: mini,
					error: error,
					disabled: disabled
				}"
			>
				<div
					@click.stop.prevent="
						objectOptions ? selectOption(item.value) : selectOption(item)
					"
					class="d-select-option"
					v-for="(item, i) in filteredItems"
					:key="i"
				>
					{{ objectOptions ? item.text : item }}
				</div>
			</div>
		</div>
		<div class="assistive-text">{{ assistive_text }}</div>
	</div>
</template>
<script>
export default {
	name: "select-input",
	data() {
		return {
			assistive_text: "",
			menuOpen: false,
			focusEvent: null,
			blurEvent: null,
			clickEvent: null,
			inputEvent: null,
			windowClickEvent: null,
			labelWidth: 0,
			labelInterval: null,
			searchTerm: ""
		};
	},

	props: {
		leadingIcon: {
			type: String,
			default: ""
		},
		items: {
			type: Array,
			default: () => []
		},
		mask: {
			type: String,
			default: null
		},
		dark: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		mini: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: ""
		},
		color: {
			type: String,
			default: "#3e80e3"
		},
		value: {
			type: [String, Object],
			default: ""
		},
		label: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "text"
		},
		large: {
			type: Boolean,
			default: false
		},
		"x-large": {
			type: Boolean,
			default: false
		},
		fab: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		error: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		hint: {
			type: String,
			default: ""
		},
		errorMessageText: {
			type: String,
			default: ""
		},
		searchable: {
			type: Boolean,
			default: false
		},
		allowItemCreation: {
			type: Boolean,
			default: false
		}
	},
	beforeUnmount() {
		clearInterval(this.labelInterval);
		this.$refs["input"].removeEventListener("focus", this.focusEvent);
		this.$refs["container"].removeEventListener("click", this.clickEvent);
		window.removeEventListener("click", this.windowClickEvent);
		this.$refs["input"].removeEventListener("input", this.inputEvent);
		this.$refs["menu"].remove();
	},
	mounted() {
		clearInterval(this.labelInterval);
		this.$nextTick(() => {
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					this.setLabelWidth();
				});
			});
			this.$watch(() => this.labelValue, this.setLabelWidth);
			if (this.hint != "") {
				this.assistive_text = this.hint;
			}
			if (this.errorMessageText != "" && this.error) {
				this.assistive_text = this.errorMessageText;
			}

			this.$refs["searchableText"] &&
				this.$refs["searchableText"].addEventListener(
					"focus",
					(this.focusEvent = () => {
						if (this.label != "") {
							this.labelWidth = Math.min(
								this.$refs.label.scrollWidth * 0.75 + 13,
								this.$el.offsetWidth - 24
							);
						}
					})
				);

			this.$refs["container"] &&
				this.$refs["container"].addEventListener(
					"click",
					(this.clickEvent = (e) => {
						e.stopPropagation();
						this.menuOpen = !this.menuOpen;
					})
				);

			window.addEventListener(
				"click",
				(this.windowClickEvent = (e) => {
					const x = e.clientX;
					const y = e.clientY;
					const el = document.elementFromPoint(x, y);
					const classes = Array.from(el.classList);
					if (classes.indexOf("d-select") < 0) {
						this.menuOpen = false;
					}
				})
			);
			this.$refs["input"] &&
				this.$refs["input"].addEventListener(
					"input",
					(this.inputEvent = () => {
						if (this.menuOpen) {
							this.menuOpen = false;
						}
					})
				);
		});
	},
	watch: {
		value() {
			this.$nextTick(this.setLabelWidth);
		},
		menuOpen(v) {
			if (v) {
				this.$nextTick(() => {
					const {
						top,
						left,
						width,
						height: iHeight
					} = this.$refs["input"].getBoundingClientRect();
					const body = document.getElementById("app");
					this.$refs["menu"].style.position = "fixed";
					this.$refs["menu"].style.top = top + (this.small ? 30 : 50) + "px";
					this.$refs["menu"].style.width = width + "px";
					this.$refs["menu"].style.zIndex = 100000;
					this.$refs["menu"].style.left = left + "px";
					body.appendChild(this.$refs["menu"]);

					this.$refs["searchableText"].focus();
					const wHeight = window.innerHeight;
					const { height } = this.$refs["menu"].getBoundingClientRect();
					if (top > wHeight - height) {
						this.$refs["menu"].style.top = wHeight - height - iHeight * 3 + "px";
					} else {
						this.$refs["menu"].style.top = top + "px";
					}
				});
			} else {
				this.setLabelWidth();
			}
		}
	},
	methods: {
		setLabelWidth() {
			this.$nextTick(() => {
				if (this.$refs.label != null && this.value != "") {
					if (this.$refs.label.scrollWidth != null) {
						this.labelInterval = setInterval(() => {
							// This interval is because there is a bug with vue that doesn't assign a width to the created ref right away.  This is the best solution to wait for an actual width value.
							if (this.$refs.label != null) {
								if (this.$refs.label.scrollWidth > 0 && this.value != "") {
									this.labelWidth = Math.min(
										this.$refs.label.scrollWidth * 0.75 + 13,
										this.$el.offsetWidth - 24
									);
									clearInterval(this.labelInterval);
								}
								if (this.value == "") {
									clearInterval(this.labelInterval);
								}
							}
						}, 0);
					}
				} else {
					this.labelWidth = 0;
				}
			});
		},
		selectOption(option, isNew = false) {
			this.$emit("update:value", option);
			this.$emit("change", option);

			if (isNew) {
				this.$emit("addNewItem", option);
			}
			this.menuOpen = false;
			this.searchTerm = "";
		}
	},
	computed: {
		filteredItems() {
			if (this.searchable && this.searchTerm != "") {
				const filtered = this.items.filter((item) => {
					return item.text.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
				});

				return filtered;
			} else {
				return this.items;
			}
		},
		labelValue() {
			return this.value != "";
		},
		objectOptions() {
			if (typeof this.items[0] == "object") {
				return true;
			} else {
				return false;
			}
		}
	}
};
</script>
<style lang="scss">
.searchable-input {
	pointer-events: auto;
	position: absolute;
	z-index: 10;
	top: 5px;
	width: calc(100% - 60px) !important;
}
.select-input {
	pointer-events: none;
	opacity: 0;
	position: absolute;
	cursor: pointer;
}
input {
	background: transparent;
	outline: none;
	border: none;
	&.select-input-input {
		&.hidden {
			opacity: 0;
			pointer-events: none;
		}
	}
	&:focus {
		outline: none;
	}
}
.assistive-text {
	position: absolute;
	bottom: -16px;
	left: 20px;
	font-size: 12px;
	color: $ww-text;
}
.d-select {
	font-family: Quicksand !important;
	i{
		color:$ww-text;
	}
	&.d-disabled {
		pointer-events: none;
		fieldset {
			border-color: $ww-text !important;
			pointer-events: none;
		}
		label {
			color: $ww-text !important;
			pointer-events: none;
		}
		input {
			color: $ww-text !important;
			pointer-events: none;
		}
		.assistive-text {
			color: $ww-text !important;
			pointer-events: none;
		}
	}
	input {
		cursor: pointer;
	}
	cursor: pointer;
	.d-select-inner {
		display: flex;
		align-items: center;
		pointer-events: none;
	}
	text-align: left;
	position: relative;
	margin-top: 0px;
	flex: 1;
	border-radius: 5px;
	padding: 0;
	padding: 5px 10px;
	max-height: 48px;
	fieldset {
		border-collapse: collapse;
		border: 1px solid $ww-borders;
		border-radius: inherit;
		bottom: 0;
		left: 0;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0px;
		transition-duration: 0.15s;
		transition-property: color;
		transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
		legend {
			border: 0;
			width: 0;
			color: inherit;
			display: table;
			white-space: normal;
			max-width: 100%;
			line-height: 11px;
			padding: 0;
			transition: all 0.3s ease;
		}
	}

	input {
		color: $ww-text;
		padding: 10px 10px;
		font-size: 16px;
		width: 100%;
		font-family: Quicksand;
		&:focus {
			outline: none;
		}
	}
	&:focus-within {
		fieldset {
			border: solid 1px $ww-highlight;

			legend {
				width: --label-width;
				transition: all 0.3s ease;
			}
		}
		label {
			color: $ww-text;
			transform: translateY(-22px) translateX(-7px) scale(0.8, 0.8) !important;
		}
		.bottom-line {
			background: #000;
		}
	}

	.filled {
		color: $ww-text;
		transform: translateY(-22px) translateX(-7px) scale(0.8, 0.8) !important;
	}

	label {
		transform-origin: top left;
		transition: all 0.3s ease;
		pointer-events: none;
		position: absolute;
		left: 26px;
		top: 15px;
		line-height: normal;
		overflow: hidden;
		padding: 0px;
		font-size: 12pt;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: $ww-dark-grey;
	}
	&.error {
		fieldset {
			border-color: $ww-error !important;
		}
		label {
			color: $ww-error !important;
		}
		input {
			color: $ww-error !important;
		}
		.assistive-text {
			color: $ww-error;
		}
	}

	&.dark-d {
		label {
			color: rgba($ww-text, 0.41);
			&.filled {
				color: $ww-text;
			}
		}
		input {
			color: $ww-text;
		}
		i {
			color: $ww-text;
		}
		.bottom-line {
			background: transparent;
		}

		&:focus-within {
			label {
				max-width: 153%;
				color: $ww-text;
				// transform:translateY(-25px) translateX(0px) scale(.7,.7);
			}
			.bottom-line {
				background: transparent;
			}
		}
	}
	&.small-d {
		i {
			font-size: 10pt;
			color:$ww-text;
		}
		label {
			line-height: normal;
			font-size: 10pt;
			top: 9px;
			&.filled {
				transform: translateY(-16px) translateX(-7px) scale(0.8, 0.8) !important;
			}
		}
		input {
			font-size: 10pt;
			padding: 3px 6px;
		}
		.bottom-line {
			background: transparent;
		}
		&:focus-within {
			label {
				max-width: 153%;
				transform: translateY(-16px) translateX(-5px) scale(0.7, 0.7) !important;
			}
			.bottom-line {
				background: transparent;
			}
		}
		// .d-select-inner {
		// }
		&.small-d {
			i {
				font-size: 10pt;
				color:$ww-text;
			}
			label {
				font-size: 10pt;
				left: 18px;
				top: 7px;
				&.filled {
					transform: translateY(-13px) translateX(3px) scale(0.8, 0.8) !important
				}
			}
			input {
				font-size: 10pt;
				padding: 3px 6px;
			}
			.bottom-line {
				background: transparent;
			}
			&:focus-within {
				label {
					max-width: 153%;
					transform: translateY(-16px) translateX(-5px) scale(0.7, 0.7) !important;
				}
				.bottom-line {
					background: transparent;
				}
			}
		}
	}
	&.mini {
		i {
			font-size: 8pt;
			padding: 0 !important;
			color:$ww-text;
		}
		label {
			font-size: 9pt;
			top: 9px;
			display: none;
			&.filled {
				
				transform: translateY(-16px) translateX(-7px) scale(0.8, 0.8) !important;
			}
		}
		input {
			font-size: 9pt;
			padding: 0px 1px;
		}
		.assistive-text {
			font-size: 8pt;
		}
	}
}
.d-select-menu {
	z-index: 10;
	box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.5);
	position: absolute;
	top: calc(100% + 20px);
	width: calc(100% - 10px);
	background: #fff;
	border-radius: 0px;
	border-radius: 10px;
	left: 5px;
	max-height: 300px;
	overflow: auto;
	.d-select-option {
		padding: 10px;
		color: $ww-text;
		cursor: pointer;
		font-size: 16px;
		&:hover {
			background: rgba($ww-accent, 0.08);
		}
	}
	&.dark-d {
		background: $ww-tertiary-dark;
		max-height: 200px;
		.d-select-option {
			color: $ww-text;
			padding: 3px;
			font-size: 12px;
			font-weight: 600;
			&:hover {
				background: rgba($ww-accent, 0.6);
			}
		}
	}
	&.small-d {
		max-height: 200px;
		.d-select-option {
			padding: 7px 10px;
			font-size: 10pt;
			font-weight: 500;
		}
	}
	&.mini {
		max-height: 200px;
		min-width: 100px;
		.d-select-option {
			padding: 7px 10px;
			font-size: 8pt;
			font-weight: 500;
		}
	}
}
</style>
