//======================================================================================================
//
// This store houses all user information and will update the application based on role and authentication.
//
//======================================================================================================

import { defineStore } from "pinia";
import { useApiStore as api } from "./apiStore";
import decode from 'jwt-decode'

import axios, { AxiosResponse } from "axios";

export const useAuthStore = defineStore("authStore", {
	state: () =>
		({
			user: {}
		}) as any,
	actions: {
		setUser(payload: any) {
			this.user = payload;
		},
		async logout() {

			localStorage.removeItem("wellwhisker_token");
			delete axios.defaults.headers.common["Authorization"];
			this.setUser({})
			this.router.push('/');

		},
		async login(payload: { email: string; password: string } | null) {

			return new Promise((resolve, reject) => {
				if (payload == null) {

				} else {
					// eslint-disable-next-line
					api()
						.post({
							endpoint: "/api/auth",
							post: { email: payload.email, password: payload.password }
						})
						.then(
							async (res: AxiosResponse) => {
								api().setApiToken(res.data.token);
								api().getApiInstance().defaults.headers.common["Authorization"] =
									"Bearer " + res.data.token;
								try {
										localStorage.setItem('wellwhisker_token', res.data.token);
										let me: any = decode(res.data.token);
										this.setUser(me);
										resolve(null);
								} catch (e) {
									reject(e);
								}
							},
							(e: any) => {
								reject(e);
							}
						);
				}
			});
		}
	}
});
