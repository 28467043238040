<template>
	<div class="d-tooltip-container">
		<div style="opacity: 0; pointer-events: none; position: absolute">
			<slot ref="slot"></slot>
		</div>

		<div class="activator-container" ref="act">
			<slot name="activator"></slot>
			<transition name="block">
				<!-- <div ref="tooltip" :class="{hidden:!showTip,[position]:position}" class="d-tooltip" v-html="message"></div> -->
				<div
					ref="tooltip"
					:class="{ hidden: !showTip, [position]: position }"
					class="d-tooltip"
				>
					<slot name="tooltip" class="tooltip-text"></slot>
				</div>
			</transition>
		</div>
	</div>
</template>
<script>
export default {
	name: "imdex-tooltip",
	props: {
		nudgeY: {
			type: Number,
			default: 0
		},
		nudgeX: {
			type: Number,
			default: 0
		},
		position: {
			type: String,
			default: "top"
		},
		delay: {
			type: Number,
			default: 0
		}
	},
	beforeUnmount() {
		if (this.nodeElm != null) {
			this.nodeElm.removeEventListener("mouseover", this.mouseoverEvent);
			this.nodeElm.removeEventListener("mouseleave", this.mouseleaveEvent);
		}

		const container = document.getElementById("tooltip-container");
		clearTimeout(this.delayTimeout);
		clearTimeout(this.removeTimeout);
		if (container && container.contains(this.tooltipEl)) {
			container.removeChild(this.tooltipEl);
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.nodeElm = this.$refs.act;
			this.tooltipEl = this.$refs.tooltip;
			const container = document.getElementById("tooltip-container");
			if (this.nodeElm == null) return;
			this.nodeElm.addEventListener(
				"mouseenter",
				(this.mouseoverEvent = () => {
					clearTimeout(this.removeTimeout);
					this.delayTimeout = setTimeout(() => {
						container.appendChild(this.tooltipEl);
						const ttw = this.tooltipEl.getBoundingClientRect().width;
						const tth = this.tooltipEl.getBoundingClientRect().height;
						const {
							width,
							top,
							left,
							height: cheight
						} = this.nodeElm.getBoundingClientRect();
						const sidew = width - ttw;
						this.tooltipEl.style.left = sidew / 2 + left + this.nudgeX + "px";
						if (this.position == "top") {
							this.tooltipEl.style.top = `calc(${top}px - ${tth}px + ${this.nudgeY}px)`;
						}
						if (this.position == "bottom") {
							this.tooltipEl.style.top = `calc(${top}px + ${cheight}px + 5px +  ${this.nudgeY}px)`;
						}

						this.showTip = true;
					}, this.delay);
				})
			);
			this.nodeElm.addEventListener(
				"mouseleave",
				(this.mouseleaveEvent = () => {
					this.showTip = false;
					clearTimeout(this.delayTimeout);

					this.removeTimeout = setTimeout(() => {
						if (container && container.contains(this.tooltipEl)) {
							container.removeChild(this.tooltipEl);
						}
					}, 2000);
				})
			);
		});
	},

	data() {
		return {
			showTip: false,
			nodeElm: null,
			mouseoverEvent: null,
			mouseleaveEvent: null,
			removeTimeout: null,
			delayTimeout: null
		};
	},
	methods: {
		mouseover() {
			this.showTip = true;
		}
	}
};
</script>
<style lang="scss">
.block-enter {
	opacity: 0;
}
.block-enter-active {
	transition: transform 0.3s ease;
}
.block-leave {
}
.block-leave-active {
	transition: transform 0.3s ease;
	opacity: 0;
}
.activator-container {
	width: 100%;
	display: inline-flex;
	align-items: center;
	position: relative;
}

.d-tooltip {
	position: absolute;
	background: #643945aa;
	color: #fff;
	padding: 7px 16px;
	font-size: 10pt;
	border-radius: 4px;
	white-space: wrap;
	transition:
		transform 0.2s ease,
		opacity 0.2s ease;
	pointer-events: none !important;
	z-index: 10000;
	transform: scale(1, 1);
	transform-origin: center, center;
	&.hidden {
		opacity: 0;
		transform: translateY(10px);
	}
	&.bottom {
	}
	&::before {
		position: absolute;
		z-index: 1001;
		content: "";
		border: solid 10px #643945aa;
		border-left-color: transparent;
		border-bottom-color: transparent;
		border-right-color: transparent;
		height: 0.5%;
		width: 0.5%;
		opacity: 0;
		left: 50%;
		transform: translateX(-50%) translateY(100%);
		bottom: 0;
	}
}
.d-tooltip-container {
	display: flex;
	align-items: center;
}
</style>
