//======================================================================================================
//
// This store handles auth Tokens and is the middleware between every API call throughout the app.
//
//======================================================================================================

import { defineStore } from "pinia";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from "axios";
import axiosRetry from "axios-retry";


let controller = new AbortController();

export const useApiStore = defineStore("apiStore", {
	state: () => ({
		initialized: false as boolean,
		apiToken: null as string | null,
		apiInstance: null as AxiosInstance | null
	}),
	actions: {
		async init() {
			if (this.initialized) {
				return;
			}
			this.initialized = true;
			this.apiInstance = axios.create({
				baseURL: '/'
			});
		},
		setApiToken(token: string) {
			this.apiToken = token;
			if (this.apiInstance) {
				this.apiInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
			}
		},
		getApiInstance(): AxiosInstance {
			if (!this.initialized) {
				throw new Error("ApiStore not initialized");
			}
			return this.apiInstance as AxiosInstance;
		},
		cancel(): void {
			controller.abort();
			controller = new AbortController();
		},
		async get(
			endpoint: string,
			additionalConfig: any = null
		): Promise<AxiosResponse<any, any>> {
			if (!this.initialized) {
				await this.init();
			}

			const config = {
				signal: controller.signal
			};

			Object.assign(config, additionalConfig);

			const response = await this.apiInstance?.get(endpoint, config);
			if (response) {
				return response;
			} else {
				throw new Error("Response is undefined");
			}
		},
		async post(
			payload: { endpoint: string; post: unknown },
			config: AxiosRequestConfig = {}
		): Promise<AxiosResponse> {
			if (!this.initialized) {
				await this.init();
			}
			const response = await this.apiInstance?.post(payload.endpoint, payload.post, {
				...config,
				signal: controller.signal
			});
			if (response) {
				return response;
			} else {
				throw new Error("Response is undefined");
			}
		}, // eslint-disable-next-line
		async put(payload: { endpoint: string; post: unknown }): Promise<AxiosResponse> {
			if (!this.initialized) {
				await this.init();
			}
			const response = await this.apiInstance?.put(payload.endpoint, payload.post, {
				signal: controller.signal
			});

			if (response) {
				return response;
			} else {
				throw new Error("Response is undefined");
			}
		},
		async delete(endpoint: string, post: unknown = null): Promise<AxiosResponse> {
			if (!this.initialized) {
				await this.init();
			}

			const config: any = {
				signal: controller.signal
			};

			if (post) {
				config["data"] = post;
			}

			const response = this.apiInstance?.delete(endpoint, config);

			if (response) {
				return response;
			} else {
				throw new Error("Response is undefined");
			}
		}
	}
});
