<template>
	<div class="d-switch" :class="{ 'disabled-d': disabled, error: error, 'dark-d': dark }">
		<div @click="selectCheckbox" class="d-switch-container" :class="{ active: value }">
			<div class="d-switch-handle"></div>
		</div>
		<label>{{ label }}</label>
	</div>
</template>
<script>
export default {
	name: "imdex-switch",
	props: {
		label: {
			type: String,
			default: ""
		},
		val: [String],
		value: [Boolean, Array, String],

		loading: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		dark: {
			type: Boolean,
			default: false
		},
		error: {
			type: Boolean,
			default: false
		},
		errorMessageText: {
			type: String,
			default: ""
		}
	},
	methods: {
		selectCheckbox() {
			if (Array.isArray(this.value)) {
				const arr = this.value;
				if (this.v) {
					arr.splice(
						arr.findIndex((item) => item == this.val),
						1
					);
				} else {
					arr.push(this.val);
				}
				this.$emit("update:value", arr);
				this.$emit("change", arr);
			} else {
				this.$emit("update:value", !this.value);

				this.$emit("change", !this.value);
			}
		}
	},
	computed: {
		v() {
			if (Array.isArray(this.value)) {
				return this.value.indexOf(this.val) > -1;
			} else {
				return this.value;
			}
		}
	}
};
</script>
<style lang="scss">
.d-switch {
	position: relative;
	padding: 0px 0px;
	display: flex;

	align-items: center;

	&:active {
		.checkbox-icon {
			&::before {
				opacity: 1;
			}
		}
	}
	.d-switch-container {
		background: $ww-grey;
		border-radius: 100px;
		width: 40px;
		display: flex;
		align-items: center;
		cursor: pointer !important;
		height: 15px;
		margin-right: 10px;
		&:hover {
			.d-switch-handle {
				background: darken($ww-grey2, 10%);
			}
		}
		&.active {
			background: lighten($ww-secondary, 40%);
			.d-switch-handle {
				background: $ww-secondary;
				transform: translateX(17px);
				// box-shadow: 0px 0px 5px $ww-secondary;
			}
		}
		.d-switch-handle {
			height: 23px;
			width: 23px;
			border-radius: 100px;
			background: $ww-grey2;
			// box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
			transition: all 0.3s ease;
		}
	}

	label {
		user-select: none;
		color: $ww-dark-grey;
	}
	&.error {
		.checkbox-icon {
			i {
				color: $ww-error;
			}
		}
		label {
			color: $ww-error;
		}
		.assistive-text {
			color: $ww-error;
		}
	}
	&.dark-d {
		.checkbox-icon {
			i {
				color: #fff;
			}
			color: #fff;
		}
	}
	&.disabled-d {
		background: transparent;
		pointer-events: none;
		.checkbox-icon {
			i {
				color: $ww-tertiary-light;
			}
		}
		label {
			color: $ww-tertiary-light;
			pointer-events: none;
		}
	}
}
</style>
