<template>
	<div
		class="d-input"
		:class="{
			'dark-d': dark,
			error: error,
			small: small,
			mini: mini,
			disabled: disabled,
			'a-right': alignText == 'right'
		}"
	>
		<fieldset ref="fieldset">
			<legend :style="`width:${labelWidth}px`" ref="legend"><span></span></legend>
		</fieldset>
		<label
			ref="label"
			:class="{ filled: value.length != 0 || isFocused, 'l-icon': leadingIcon != '' }"
		>
			{{ label }}
		</label>
		<div class="d-input-inner">
			<i class="fal fa-calendar"></i>
			<DatePicker
				auto-apply
				:clearable="false"
				:min-date="min != null ? min : null"
				:enable-time-picker="false"
				@change="($event) => updateValue($event)"
				v-model="tempVal"
			/>
		</div>
		<div class="assistive-text">{{ assistive_text }}</div>
	</div>
</template>
<script>
import DatePicker from "@vuepic/vue-datepicker";
import moment from "moment";
import { defineComponent } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
export default defineComponent({
	name: "datetime-picker",
	components: {
		DatePicker
	},
	data() {
		return {
			assistive_text: "",
			labelWidth: 0,
			labelInterval: null,
			isFocused: false,
			passwordVisible: false,
			tempVal: null,
			moment
		};
	},
	props: {
		unit: {
			type: String,
			default: ""
		},
		slider: {
			type: Boolean,
			default: false
		},
		lazy: {
			type: Boolean,
			default: false
		},
		trailingIcon: {
			type: String,
			default: ""
		},
		leadingIcon: {
			type: String,
			default: ""
		},
		mask: {
			type: String,
			default: null
		},
		dark: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: ""
		},
		color: {
			type: String,
			default: "#3e80e3"
		},
		value: {
			type: [String, Number, Date],
			default: ""
		},
		min: {
			type: [Date, null],
			default: null
		},
		max: {
			type: [Date, null],
			default: null
		},
		label: {
			type: String,
			default: null
		},
		type: {
			type: String,
			default: "text"
		},
		small: {
			type: Boolean,
			default: false
		},
		mini: {
			type: Boolean,
			default: false
		},
		large: {
			type: Boolean,
			default: false
		},
		"x-large": {
			type: Boolean,
			default: false
		},
		fab: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		error: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		hint: {
			type: String,
			default: ""
		},
		errorMessageText: {
			type: String,
			default: ""
		},
		alignText: {
			type: String,
			default: "left"
		},
		showNumberIncrement: {
			type: Boolean,
			default: false
		}
	},

	beforeUnmount() {
		clearInterval(this.labelInterval);
	},
	async mounted() {
		this.tempVal = this.value;

		clearInterval(this.labelInterval);
		this.$nextTick(() => {
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					this.setLabelWidth();
				});
			});
			// this.$watch(() => this.labelValue, this.setLabelWidth)

			if (this.hint != "") {
				this.assistive_text = this.hint;
			}
			if (this.errorMessageText != "" && this.error) {
				this.assistive_text = this.errorMessageText;
			}
			if (this.value.length != 0 && this.value != 0 && this.label != "") {
				// this.$refs.legend.style.width = this.labelWidth * 1 + 10 + 'px';
			} else {
				// this.$refs.legend.style.width = (this.labelWidth * .8) + 10 + 'px';
			}
			if (this.$refs.input != null) {
				this.$refs.input.addEventListener("focus", () => {
					this.isFocused = true;
					if ((this.value.length != 0 && this.value != 0) || this.label == "") {
						//this.$refs.legend.style.width = (this.labelWidth * 1) + 10 + 'px';
					} else {
						//    this.$refs.legend.style.width = this.labelWidth * 0.8 + 14 + 'px';
					}
				});
			}

			if ((this.value.length != 0 && this.value != 0) || this.label != "") {
				//this.$refs.legend.style.width = (this.labelWidth * 1) + 10 + 'px';
			} else {
				//   this.$refs.legend.style.width = this.labelWidth * 0.8 + 14 + 'px';
			}
			if (this.value.length == 0) {
				//    this.$refs.legend.style.width = '0px';
			}

			if (this.$refs.input != null) {
				this.$refs.input.addEventListener("blur", () => {
					this.isFocused = false;
					if (this.value.length == 0) {
						//     this.$refs.legend.style.width = '0px';
					}
				});
			}
		});
	},
	watch: {
		tempVal(v) {
			this.$emit("update:value", v);
		},
		isFocused() {
			this.setLabelWidth();
		},
		value() {
			this.$nextTick(this.setLabelWidth);
		}
	},
	methods: {
		focusInput() {
			if (this.slider) {
				this.showSlider;
			}
		},
		setLabelWidth() {
			this.$nextTick(() => {
				if (this.$refs.label != null) {
					if (this.label == null) return;
					this.labelInterval = setInterval(() => {
						// This interval is because there is a bug with vue that doesn't assign a width to the created ref right away.  This is the best solution to wait for an actual width value.
						if (this.$refs.label != null) {
							if (
								(this.$refs.label.scrollWidth > 0 && this.value.length != 0) ||
								this.isFocused
							) {
								this.labelWidth = Math.min(
									this.$refs.label.scrollWidth * 0.75 + 13,
									this.$el.offsetWidth - 24
								);
								clearInterval(this.labelInterval);
							}
							if (!this.isFocused && this.value.length == 0) {
								this.labelWidth = 0;
								clearInterval(this.labelInterval);
							}
						}
					}, 0);
				} else {
					this.labelWidth = 0;
				}
			});
		},
		inputEvent(type, value) {
			if (this.lazy) {
				if (type == "change") {
					this.$emit("update:value", value);
				}
			} else {
				this.$emit("update:value", value);
			}
		}
	},
	computed: {
		computedType() {
			if (this.type == "password") {
				return this.passwordVisible ? "text" : "password";
			} else {
				return this.type;
			}
		},
		labelValue() {
			return this.value.length != 0 || this.isFocused;
		}
	}
});
</script>

<style lang="scss">
.dp__icon {
	&:nth-child(1) {
		display: none !important;
	}
}
.dp__inner_nav {
	.dp__icon {
		display: block !important;
		color: $ww-grey2;
	}
}
.dp__menu {
	font-family: Quicksand !important;
	border: none !important;
	box-shadow: 0px -3px 14px -9px rgba(0, 0, 0, 0.5);
	padding: 15px;
	border-radius: 0 !important;
}
.dp__arrow_top {
	display: none !important;
}
.dp__calendar_header_item {
	font-weight: normal !important;
	color: $ww-dark;
	text-transform: uppercase;
	font-size: 0.7em !important;
	line-height: 3em;
}
.dp__input {
	outline: none;
	width: 100%;
	font-size: var(--dp-font-size);
	line-height: calc(var(--dp-font-size) * 1.7);
	padding: var(--dp-input-padding);
	font-family: Quicksand !important;
	color: var(--dp-text-color);
	box-sizing: border-box;
	border: none !important;
}
.dp__cell_highlight {
	background-color: $ww-secondary !important;
}
.dp__action_buttons {
	display: flex;
	flex: 0;
	align-items: center;
	justify-content: flex-end;
	gap: 10px;
	margin-left: auto;
}
.dp__action_button {
	padding: 7px 12px !important;
	font-size: 12px;
	height: unset !important;
	border: none !important;
}
.dp__action_select {
	background: $ww-secondary !important;
	border: none !important;
	transition: all 0.3s ease;
	&:hover {
		background: darken($ww-secondary, 5%) !important;
	}
}
.dp__action_cancel {
	color: $ww-highlight !important;
	transition: all 0.3s ease;
	&:hover {
		background: $ww-borders !important;
		color: #fff !important;
	}
}
</style>

<style scoped lang="scss">
input {
	outline: none;
	border: none;
	&:focus {
		outline: none;
	}
}
.assistive-text {
	position: absolute;
	bottom: -16px;
	left: 20px;
	font-size: 12px;
	color: $ww-text;
}
.d-input {
	.d-input-inner {
		display: flex;
		align-items: center;

		i {
			color: #66727c;

			&.leading {
				transform: translateX(7px);
				margin-right: 7px;
			}
		}
	}
	text-align: left;
	&.a-right {
		input {
			text-align: right;
		}
	}
	position: relative;
	margin-top: 0px;
	flex: 1;
	background: transparent;
	border-radius: 5px;
	padding: 0;
	padding: 5px 10px;
	fieldset {
		border-collapse: collapse;
		border: 1px solid $ww-borders;
		border-radius: inherit;
		bottom: 0;
		left: 0;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0px;
		transition-duration: 0.15s;
		transition-property: color;
		transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
		legend {
			border: 0;
			width: 0;
			color: inherit;
			display: table;
			white-space: normal;
			max-width: 100%;
			line-height: 11px;
			padding: 0;
			transition: all 0.3s ease;
		}
	}

	input {
		color: #000;
		padding: 10px 10px;
		margin-right: -10px;
		font-size: 16px;
		width: 100%;
		&::-webkit-outer-spin-button {
			opacity: 0;
		}
		&::-webkit-inner-spin-button {
			opacity: 0;
		}
		&:focus {
			outline: none;
		}
	}
	.showNumberIncrement {
		&::-webkit-outer-spin-button {
			opacity: 1;
		}
		&::-webkit-inner-spin-button {
			opacity: 1;
		}
	}
	&:focus-within {
		fieldset {
			border: solid 1px $ww-highlight;

			legend {
				width: --label-width;
				transition: all 0.3s ease;
			}
		}
		label {
			color: $ww-text;
			transform: translateY(-22px) translateX(-7px) scale(0.8, 0.8) !important;
		}
		.bottom-line {
			background: #000;
		}
		&.small {
			label {
				transform: translateY(-16px) translateX(-7px) scale(0.8, 0.8) !important;
			}
		}
		&.mini {
			label {
				transform: translateY(-16px) translateX(-7px) scale(0.8, 0.8) !important;
			}
		}
	}

	.filled {
		color: $ww-text;
		z-index: 1;
		transform: translateY(-22px) translateX(-7px) scale(0.8, 0.8) !important;
	}

	label {
		&.l-icon {
			transform: translateX(15px);
		}
		transform-origin: top left;
		transition: all 0.3s ease;
		pointer-events: none;
		position: absolute;
		line-height: normal;
		left: 26px;
		top: 15px;
		overflow: hidden;
		padding: 0px;
		font-size: 12pt;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: $ww-dark-grey;
	}
	.input-slider {
		min-width: 130px;
		top: calc(100% - 4px);
		position: absolute;
		right: 0;
		background: #fff;
		padding: 20px 20px;
		border-radius: 4px;
		z-index: 1000;
		box-shadow: 0px 10px 22px -5px rgba(0, 0, 0, 0.8);
	}
	&.error {
		fieldset {
			border-color: $ww-error !important;
		}
		label {
			color: $ww-error !important;
		}
		input {
			color: $ww-error !important;
		}
		.assistive-text {
			color: $ww-error;
		}
	}
	&.disabled {
		fieldset {
			border-color: $ww-tertiary-light !important;
			pointer-events: none;
		}
		label {
			color: $ww-tertiary-light !important;
			pointer-events: none;
		}
		input {
			color: $ww-tertiary-light !important;
			pointer-events: none;
		}
		.assistive-text {
			color: $ww-tertiary-light !important;
			pointer-events: none;
		}
	}
	&.dark-d {
		fieldset {
			border: solid 1px #ffffff30;
		}
		label {
			color: rgba(#fff, 0.44);
			&.filled {
				color: #fff !important;
			}
		}
		input {
			color: #fff;
		}
		.bottom-line {
			background: transparent;
		}
		&:focus-within {
			fieldset {
				border: solid 1px #fff;
			}
			label {
				color: #fff;
				max-width: 153%;
				transform: translateY(-25px) translateX(0px) scale(0.7, 0.7);
			}
			.bottom-line {
				background: transparent;
			}
		}
	}

	&.small {
		label {
			font-size: 10pt;
			top: 9px;
			&.filled {
				transform: translateY(-16px) translateX(-7px) scale(0.8, 0.8) !important;
			}
		}
		input {
			font-size: 10pt;
			padding: 3px 6px;
		}
		.assistive-text {
			font-size: 10pt;
		}
	}
	&.mini {
		label {
			font-size: 9pt;
			top: 9px;
			display: none;
			&.filled {
				transform: translateY(-16px) translateX(-7px) scale(0.8, 0.8) !important;
			}
		}
		input {
			font-size: 9pt;
			padding: 0px 1px;
		}
		.assistive-text {
			font-size: 8pt;
		}
	}
}
</style>
