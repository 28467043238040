import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "menu" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar", {'open':$props.open}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.menu, (nav, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["menu-item", {'active':_ctx.$route.path.includes(nav.link)}]),
          key: i,
          onClick: ($event: any) => (_ctx.$router.push(nav.link))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["fad", nav.icon])
          }, null, 2),
          _createTextVNode(" " + _toDisplayString(nav.label), 1)
        ], 10, _hoisted_2))
      }), 128))
    ])
  ], 2))
}