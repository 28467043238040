<template>
	<button
		:type="type"
		class="btn-b"
		:class="{
			'small-d': small,
			'mini-d': mini,
			'disabled-d': disabled,
			'large-d': large,
			'block-d': block,
			'text-d': text,
			'outlined-d': outlined,
			'large-d': large,
			'compact-d': compact,
			'inline-d': inline,
			'dark-d': dark,
			'loading-d': loading,
			'rounded-d':rounded,
			'white-d': white,
			'elevated-d': elevated,
			fab: fab,
			secondary: secondary
		}"
	>
		<i class="fal pre" v-if="prependIcon != ''" :class="prependIcon"></i>
		<div :class="{'hidden':loading}">
		<slot></slot>
		</div>
		<d-spinner class="spiner" v-if="loading"  :size="2" :width="20" color="#fff"></d-spinner>
		<i class="fa post" v-if="appendIcon != ''" :class="appendIcon"></i>
	</button>
</template>
<script>
export default {
	name: "imdex-button",
	props: {
		prependIcon: {
			type: String,
			default: ""
		},
		appendIcon: {
			type: String,
			default: ""
		},
		type: {
			type: String,
			default: "submit"
		},

		block: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},

		large: {
			type: Boolean,
			default: false
		},
		dark: {
			type: Boolean,
			default: false
		},
		outlined: {
			type: Boolean,
			default: false
		},

		compact: {
			type: Boolean,
			default: false
		},
		mini: {
			type: Boolean,
			default: false
		},
		inline: {
			type: Boolean,
			default: false
		},
		text: {
			type: Boolean,
			default: false
		},

		fab: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		secondary: {
			type: Boolean,
			default: false
		},
		rounded:{
			type:Boolean,
			default: false
		},
		white:{
			type:Boolean,
			default: false
		},
		elevated:{
			type:Boolean,
			default: false
		},
	},
	methods: {}
};
</script>
<style lang="scss">
.spiner{
	position: absolute;
	top:50%;
	left:50%;
	transform:translateX(-50%) translateY(-50%);
	z-index: 10000;
	
}
.hidden{
	opacity: 0;
	// position: absolute;
	//height:0;
}
.btn-b {
	outline: none;
	border: none;
	position: relative;
	text-align: center;
	padding: 12px 24px;
	font-family: Quicksand;
	display: inline-block;
	font-size: 16px;
	border-radius: 3px;
	transition: all 0.1s ease;
	box-sizing: border-box;

	white-space: nowrap;
	background: $ww-highlight;
	div{
		display:inline-block;
	}
	&:hover {
		cursor: pointer;
		background: $ww-accent;
		color: #fff;
	}

	color: #fff;
	&.fab {
		height: 50px;
		width: 50px;
		border-radius: 50px;
		align-items: center;
		justify-content: center;
		padding: 0 !important;
		font-weight: 500;
		display: inline-flex;
	}
	&.secondary {
		border-color: $ww-secondary;
		background: $ww-secondary;

		&:hover {
			border-color: $ww-secondary;
			background: darken($ww-secondary, 5%);
		}
	}
	i {
		color: #fff;
		&.pre {
			margin-right: 10px;
		}
		&.post {
			margin-left: 10px;
		}
	}

	&.outlined-d {
		border: solid 2px $ww-highlight;
		background: transparent;
		color: $ww-highlight;
		i {
			color: $ww-highlight;
		}
		&:hover {
			border: solid 2px $ww-accent;
			background: $ww-accent;
			color: #fff;
			i {
				color: #fff;
			}
		}
		&.secondary {
			border-color: $ww-secondary;
			color: $ww-secondary;
			i {
				color: $ww-secondary;
			}
			&:hover {
				border-color: $ww-secondary;
				background: $ww-secondary;
				color: #fff;
			}
		}
	}

	&.text-d {
		background: transparent;
		color: $ww-highlight;
		i {
			color: $ww-highlight;
		}
		&:hover {
			background: $ww-borders;
			color: #fff;
			i {
				color: #fff;
			}
		}
		&.dark-d {
			color: #fff;
			i {
				color: #fff;
			}
			&:hover {
				background: #ffffff30;
				color: #fff;
				i {
					color: #fff;
				}
			}
		}
		&.secondary {
			color: $ww-secondary;
			&:hover {
				background: $ww-secondary;
				color: #fff;
			}
		}
	}
	&.elevated-d{
		box-shadow: 0px 4px 10px -7px rgba(0,0,0,0.3);
	}
	&.rounded-d{
		border-radius: 100px !important;
	}
	&.white-d{
		background:#fff;
		color:$ww-text;
		&:hover{
			background:$ww-accent;
			color:#fff;
			i{
				color:#fff;
			}
		}
		i{
			color:$ww-accent;
		}
	}
	&.small-d {
		padding: 7px 15px;
		font-size: 15px;
		
		&.fab {
			height: 35px;
			width: 35px;
		}
	}
	&.compact-d {
		padding: 7px 12px;
		font-size: 12px;
		&.fab {
			height: 35px;
			width: 35px;
		}
	}
	&.mini-d {
		padding: 4px 7px;
		font-size: 12px;
		&.fab {
			height: 25px;
			width: 25px;
		}
	}
	&.large-d {
		padding: 30px 24px;
		font-size: 24px;
	}
	&.inline-d {
		padding: 2px 4px;
		font-size: 12pt;
	}

	&.block-d {
		padding: 20px 40px;
		width: 100%;
		text-align: center;
	}
	&.loading-d {
		background: $ww-accent !important;
		// color: #00000030;
		&.secondary {
			background: $ww-secondary !important;
			color: #fff;
		}
		&.dark-d {
			background: #00000030 !important;
			color: #ffffff80;
		}
	}
	&.disabled-d {
		pointer-events: none;
		background: $ww-light !important;
		&.text-d {
			background: transparent !important;
		}
		color: #00000030;
		&.dark-d {
			background: #00000030 !important;
			color: #ffffff80;
		}
	}
}
</style>
