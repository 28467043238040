<template>
    <div class="pet-dash">
        
        <div class="pet-dash-top">
            <div class="pet-dash-name">{{ pet }}</div>
        </div>
        
        <div class="pet-dash-body">
            <div class="pet-timeline">
                <Timeline />
            </div>
            <div class="pet-charts">
                <div v-if="false" class="chart-container">
                    <div class="chart-title">Food</div>
                    <div>
                        <DoughnutChart :plugins="[plugin]" :options="{responsive:true}" :chartData="testData" />
                    </div>
                    
                </div>
                
                <WeightChart :pet="pet" />
                <div  class="chart-container">
                    <div class="chart-title">Hot Actions</div>
                    <div class="pet-hot-actions">
                        <div class="hot-action" v-for="(action, i) in hotActions" :key="i">
                            <div class="action-icon">
                            <img :src="require(`@/assets/SVG/${action.icon}.svg`)" alt="">
                            </div>
                            <div class="hot-action-label">{{ action.label }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import {defineComponent, ref, computed, reactive, watch} from 'vue';
import WeightChart from './charts/weightChart.vue'
import moment from 'moment-timezone';
import Timeline from './charts/Timeline.vue';
export default defineComponent({
    name:'PetDash',
    components:{
        WeightChart,
        Timeline
    },
    props:{
        pet:{
            type:String,
            default:''
        }
    },
    setup(props) {
        let addWeightDialog = ref(false);
        const currentWeight = ref('');
        const weightChart = ref();
        const weights = reactive([
                {
                    'name':'Khaleesi',
                    'weight':10.1,
                    date:moment('09/10/2023').toDate()
                },
                {
                    'name':'Khaleesi',
                    'weight':10.2,
                    date:moment('10/12/2023').toDate()
                },
                {
                    'name':'Khaleesi',
                    'weight':10.0,
                    date:moment('11/16/2023').toDate()
                },
                {
                    'name':'Khaleesi',
                    'weight':10.1,
                    date:moment('12/13/2023').toDate()
                },

            ]);
        
        const minWeight = computed(()=>weights.map((item)=>item.weight).sort((a,b)=>a-b)[0] as any);
        const maxWeight = computed(()=>weights.map((item)=>item.weight).sort((a,b)=>a-b)[weights.length - 1] as any);

        watch(minWeight, (v)=>{
            console.log('min', v);
            weightChart.value.chartInstance.options.scales.y.min = ((v as any) - 1);
            weightChart.value.chartInstance.options.scales.y.max = (maxWeight.value as any) + 1;
            weightChart.value.update();
        })
        watch(maxWeight, (v)=>{
            console.log('max', v);
            weightChart.value.chartInstance.options.scales.y.max = ((v as any) + 1);
            weightChart.value.chartInstance.options.scales.y.min = Number(minWeight.value) - 3;
            console.log(weightChart.value.chartInstance.options.scales.y);
            weightChart.value.update();
        })

        const weightCheck = computed(()=>{
            let currentWeight = weights[weights.length - 1].weight;
            if(currentWeight <= 11 && currentWeight >= 9 ){
                return 'good'
            }
            if(currentWeight > 13){
                return 'obese'
            }
            if(currentWeight < 7){
                return 'thin'
            }
            if(currentWeight < 9){
                return 'underweight'
            }
            
            if(currentWeight > 11){
                return 'overweight'
            }
            
        })
        const submitWeight = () => {
            weights.push({
                name: props.pet,
                weight: Number(currentWeight.value),
                date: new Date()
            })
            addWeightDialog.value = false;
            console.log(weightChart.value.chartInstance);
            weightChart.value.chartInstance.data.datasets[0].data.splice(0, 1);
            weightChart.value.chartInstance.data.labels.splice(0, 1)
            weightChart.value.update();
            weightChart.value.chartInstance.data.labels.push(moment().format('MMM DD, YYYY'))
            
            weightChart.value.chartInstance.data.datasets[0].data.push(Number(currentWeight.value))
            
           
            weightChart.value.update();
            
        }
        const testData = {
            labels: ['hard', 'wet', 'treats'],
            datasets: [
                {
                    data: [30, 40, 5],
                    backgroundColor: ['#b14c68', '#643945', '#f8f6f7'],
                },
            ],
            scales:{
                x:{

                }
            }
        };
        const lineData = {
            labels: weights.map((item)=>moment(item.date).format('MMM DD, YYYY')),
            datasets: [
                {
                    labels: weights.map((item)=>item.weight + 'lbs'),
                    data: weights.map((item)=>item.weight),
                    pointRadius: 3,
                    pointHoverRadius: 5,

                    borderColor: (context: any)=>{
                        const chart = context.chart;
                        const {ctx, chartArea} = chart;
                        const followFill = ctx.createLinearGradient(0, 300, 0, 100);
                        followFill.addColorStop(0, "#b14c6880");
                        followFill.addColorStop(1, "#64394580");
                        return followFill;
                    },
                    pointBackgroundColor: (context: any)=>{
                        const chart = context.chart;
                        const {ctx, chartArea} = chart;
                        const followFill = ctx.createLinearGradient(0, 300, 0, 100);
                        followFill.addColorStop(0, "#b14c6880");
                        followFill.addColorStop(1, "#64394580");
                        return followFill;
                    },
                    backgroundColor: (context: any)=>{
                        const chart = context.chart;
                        const {ctx, chartArea} = chart;
                        const followFillHover = ctx.createLinearGradient(0, 300, 0, 100);
                        followFillHover.addColorStop(0, "#64394520");
                        followFillHover.addColorStop(1, "#d0612920");    
                        return followFillHover;     
                    },
                    hoverBackgroundColor: (context: any)=>{
                        const chart = context.chart;
                        const {ctx, chartArea} = chart;
                        const followFillHover = ctx.createLinearGradient(0, 300, 0, 100);
                        followFillHover.addColorStop(0, "#64394520");
                        followFillHover.addColorStop(1, "#d0612920");
                        return followFillHover;     
                    }
                },
            ]
        }

        const lineOptions = {
            responsive:true,
            maintainAspectRatio: false,
            plugins:{
                legend:{
                    display:false,
                },
                tooltip:{
                    intersect: false,
                    backgroundColor: "#643945",
                    borderRadius: 0,
                    titleFontSize: 11,
                    titleFontFamily: "Quicksand",
                    titleFontColor: "#808080",
                    bodyFontColor: "#fff",
                    bodyFontSize: 12,
                    bodyFontFamily: "Quicksand",
                    displayColors: false
                }
            },
            showLine:true,
            fill:true,
            tension:.4,
            scales: {
            y:{
                min: (Number(minWeight.value) - 1),
                max: (Number(maxWeight.value) + 1),
                ticks: {
                  font:{
                    size:12,
                  },
                  fontColor: "#00000080",
                  fontFamily: "Quicksand"
                },
                grid: {
                  color: "rgba(0, 0, 0, .05)",
                  zeroLineColor: "rgba(0,0,0,0.1)"
                }
              },
            x:{
                type: "time",
                // time: {
                //   unit: "month",
                //   min: new Date("2019-02-10"),
                //   max: new Date()
                // },
                stacked: false,
                ticks: {
                  size: 10,
                  fontFamily: "Quicksand",
                  autoSkip: true,
                  beginAtZero: true,
                  maxTicksLimit: 10,
                  fontColor: "#00000080",
                  maxRotation: 0,
                  minRotation: 0
                },
                grid: {
                  color: "rgba(0, 0, 0, 0)",
                  zeroLineColor: "rgba(0,0,0,0.1)"
                },
                categorySpacing: 10,
                barPercentage: 0.7
              },
              
        
          },
        }
        const image = new Image();
        image.src = require('@/assets/SVG/bowl.svg');
        const gradientPlugin = {
            id:'gradientPlugin',
            beforeDraw: (chart:any) => {
                var ctx = chart.ctx;

            }
        }

        const plugin = {
        id: 'customCanvasBackgroundImage',
        beforeDraw: (chart: any) => {
            if (image.complete) {
            const ctx = chart.ctx;
            const {top, left, width, height} = chart.chartArea;

            const x = left +  width / 2 - image.width / 8;
            const y = top +   height / 2 - image.height / 8;
            ctx.drawImage(image, x, y, image.width / 4, image.height / 4);
            } else {
                image.onload = () => chart.draw();
            }
        }
        };
        return { testData, plugin, lineData, lineOptions, weights, addWeightDialog, currentWeight, submitWeight, weightChart, weightCheck, minWeight, maxWeight };
    },
    data(){
        return {
            moment,
            dateScale: 'm',
           
        }
    },
    computed:{
        hotActions(){
            return [
                {
                    name:'throw_up',
                    label:'Found Throw Up',
                    icon:'throwup',
                    color:'#ff9900'
                },
                {
                    name:'no_eat',
                    label:'Not Eating',
                    icon:'food',
                    color:'#ff0099'
                },
                {
                    name:'outside_litterbox',
                    label:'Peeing/Pooing Outside Litterbox',
                    icon:'poo',
                    color:'#453333'
                },
                
            ]
        }
    },
    methods:{
    },
    
})
</script>
<style lang="scss">
.weight-entry{
    display:flex;
    align-items: center;
    max-width:200px;
    gap:15px;
}
.pet-dash-body{
    padding:25px 0px;
}
.pet-dash{
    padding-top:25px;
    .pet-dash-top{
        display:flex;
        align-items: center;
        .pet-dash-name{
            color:$ww-text;
            font-size:20pt;
        }
    }
}
.pet-charts{
    display:grid;
    grid-template-columns: 1fr .5fr;
    @include media-query($small){
        grid-template-columns: 1fr;
    }
    gap:25px;
    position:relative
    
}
.pet-timeline{
    display:flex;
    width:100%;
    
    margin-bottom:25px;
    position:relative;
}
.chart-container{
        background:#fff;
        border-radius: 10px;
        width:100%;
        box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.3);
        padding:25px;
        position: relative;
        .chart-top{
            display:flex;
            align-items: center;
            .chart-value{
                margin-left: auto;
                .weight{
                    font-size:16pt;
                    display:flex;
                    align-items:center;
                    gap:10px;
                    font-weight: bold;
                    &.good{
                        color:#3da22b;
                    }
                    &.underweight{
                        color:#ff9000;
                    }
                    &.overweight{
                        color:#ff9000;
                    }
                    &.thin{
                        color:#9b3932;
                    }
                    &.obese{
                        color:#9b3932;
                    }
                    .fa-info-circle{
                        color:$ww-text;
                        font-size:.8em;
                        opacity: .5;
                        transition: all .3s ease;
                        &:hover{
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .chart-title{
            font-size:15pt;
            color:$ww-text;
            padding:7px 0px;
            display:flex;
            align-items:center;
            gap:10px;
        }
    }
.pet-hot-actions{
    display:flex;
    
    gap:15px;
    margin-top:15px;
    flex-wrap:wrap;
    flex-direction: column;

    .hot-action{
        
        padding:25px;
        flex-basis: 100%;
        flex:1;
        display:flex;
       
        
        align-items:center;
        gap:16px;
        align-items: center;
        border-radius: 10px;
        transition:all .3s ease;
        background:lighten($ww-accent, 47%);
        cursor: pointer;
        // box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.3);
        
        &:hover{
            background:lighten($ww-accent,10%);
            .hot-action-label{
                color:#fff;
            }
        }
        .action-icon{
            height:50px;
            img{
                max-width:50px;
                width:100%;
                height:100%;
            }
        }
        .hot-action-label{
            font-size:12pt;
            font-weight: bold;
            color:$ww-text;
        }
    }
}
</style>