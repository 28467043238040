<template>
	<div class="table-container">
		<div v-if="title" class="table-controls">
			<div v-if="title">{{ title }}</div>
		</div>
		<table v-if="!loading" ref="table" class="table-data">
			<thead>
				<tr
					:style="
						options != null && options.thead != null && options.thead.background != null
							? `background:${options.thead.background}`
							: ``
					"
				>
					<th
						@click="checkIsSortable(thead) ? sortBy(thead) : null"
						v-for="(thead, i) in tableData.columns"
						:style="getStyleFromOptions(thead)"
						:key="i"
					>
						<span style="display: flex; gap: 5px; align-items: center">
							<slot
								v-if="isVisible('h-' + thead)"
								:row="thead"
								v-bind:value="thead"
								:name="'h-' + thead"
							></slot>
							<span v-else>{{ formatHeader(thead) }}</span>
							<i
								v-if="sortName == thead"
								class="fa"
								:class="{ 'fa-arrow-down': sort > 0, 'fa-arrow-up': sort < 0 }"
							></i>
						</span>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(tr, i) in filteredData" :key="i">
					<td
						v-for="(td, t) in tableData.columns"
						:key="t"
						:style="getRowStyleFromOptions()"
					>
						<slot
							v-if="isVisible(td)"
							:row="tr"
							v-bind:value="tr[td]"
							:name="td"
						></slot>
						<span v-else>{{ tr[td] }}</span>
					</td>
				</tr>
			</tbody>
		</table>
		<div v-if="loading" class="table-loading">
			<imdex-spinner width="40" size="3" indeterminite color="#808080"></imdex-spinner>
		</div>
		<div v-if="!loading" class="table-footer">
			<div class="table-pagination">
				<div class="per-page" @click.stop="perPageSelector = !perPageSelector">
					{{ perPage }} per page <i class="fa fa-angle-down"></i>
					<div v-if="perPageSelector" class="per-page-selector-menu">
						<div
							@click.stop="(perPage = 10), (perPageSelector = false)"
							class="per-page-option"
						>
							10 per page
						</div>
						<div
							@click.stop="(perPage = 20), (perPageSelector = false)"
							class="per-page-option"
						>
							20 per page
						</div>
						<div
							@click.stop="(perPage = 50), (perPageSelector = false)"
							class="per-page-option"
						>
							50 per page
						</div>
					</div>
				</div>
				<div>
					{{ perPage * (page - 1) + 1 }} -
					{{
						tableData.tableData.length >= perPage * page
							? perPage * page
							: tableData.tableData.length
					}}
					of {{ tableData.tableData.length }}
				</div>
				<div>
					<imdex-btn @click="prevPage" :disabled="page == 1" text compact
						><i class="fal fa-chevron-left"></i
					></imdex-btn>
					<imdex-btn @click="nextPage" text :disabled="!hasNextPage" compact
						><i class="fal fa-chevron-right"></i
					></imdex-btn>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
	name: "imdex-table",
	props: {
		title: {
			type: String,
			default: ""
		},
		value: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		tableData: {

			type: Object ,
			default: () => {
				return {
					columns: ["example"], 
					tableData: [],
					options: {}
				};
			}
		},
		onPageChangeFunc: {
			type: Function ,
			default: null
		}
	},
	data: () => {
		return {
			sort: 1,
			sortName: "",
			page: 1,
			perPage: 10,
			perPageSelector: false
		};
	},
	computed: {
		options():any {
			return this.tableData.options;
		},
		hasNextPage():any {
			return this.tableData.tableData.length > this.perPage * this.page;
		},
		filteredData():any {
			const d = this.sort;
			const td = [...this.tableData.tableData];
			if (this.sortName != "") {
				td.sort((a, b) => {
					if (a[this.sortName] > b[this.sortName]) return 1 * d;
					if (a[this.sortName] < b[this.sortName]) return -1 * d;
					return 0;
				});
			}

			return td.slice(this.perPage * (this.page - 1), this.perPage * this.page);
		}
	},
	methods: {
		// eslint-disable-next-line
		getCurrentPageItems() {
			return this.filteredData;
		},
		checkIsSortable(column: any) {
			if (this.options == null) return true;
			if (
				this.options.columns != null &&
				Object.keys(this.options.columns).indexOf(column) > -1
			) {
				if (this.options.columns[column].sortable != null) {
					return this.options.columns[column].sortable;
				}
			} else {
				return true;
			}
		}, // eslint-disable-next-line
		getStyleFromOptions(column: any) {
			if (this.options == null) return "";
			let style = ``;
			if (
				this.options.columns != null &&
				Object.keys(this.options.columns).indexOf(column) > -1
			) {
				if (this.options.columns[column].maxWidth != null) {
					style = style.concat(`width:${this.options.columns[column].maxWidth}`);
				}
				if (this.options.columns[column].minWidth != null) {
					style = style.concat(`width:${this.options.columns[column].minWidth}`);
				}
			}
			return style;
		},
		getRowStyleFromOptions() {
			if (this.options == null) return ``;
			let style = ``;
			if (
				this.options.rows != null &&
				Object.keys(this.options.rows).indexOf("spacing") > -1
			) {
				if (this.options.rows.spacing != null) {
					style = style.concat(`padding:${this.options.rows.spacing} 8px;`);
				}
			}
			return style;
		},
		formatHeader(header: string) {
			return header.replaceAll("_", " ");
		},
		prevPage() {
			this.page = this.page - 1;

			if (this.onPageChangeFunc) {
				this.onPageChangeFunc();
			}
		},
		async nextPage() {
			this.page = this.page + 1;
			// eslint-disable-next-line
			const query: any = this.$route.query;
			query.page = this.page;
			query.limit = this.perPage;

			if (this.onPageChangeFunc) {
				this.onPageChangeFunc();
			}

			await this.$router.push({ query });
		},
		sortBy(column: string) {
			if (this.sortName == column) {
				this.sort = this.sort * -1;
			}
			this.sortName = column;
		},
		isVisible(name: string) {
			const slotlist = Object.keys({ ...this.$slots });
			return slotlist.filter((item) => item == name).length > 0;
		}
	},
	watch: {
		value(v) {
			if (v) {
				setTimeout(() => {
					this.$emit("update:value", false);
				}, 3000);
			}
		},
		filteredData(v) {
			if (!v.length && this.page > 1) {
				this.prevPage();
			}
		}
	}
});
</script>
<style scoped lang="scss">
.per-page {
	position: relative;
	cursor: pointer;
	color: #808080;
	span {
		color: $ww-grey;
	}
	&:hover {
		color: #000;
	}
	.per-page-selector-menu {
		position: absolute;
		min-width: 120px;

		right: 0;

		background: #fff;
		border-radius: 4px;
		top: 100%;
		box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);

		.per-page-option {
			width: 100%;
			white-space: nowrap;
			padding: 5px 15px;
			font-size: 12pt;
			color: #808080;
			cursor: pointer;
			&:hover {
				background: $ww-light;
			}
		}
	}
}
.table-container {
	width: 100%;
	.table-footer {
		display: flex;
		padding: 10px 16px;
		background: $ww-neutral;
		// border:solid 1px $ww-grey;
		border-top: none;
		.table-pagination {
			display: flex;
			gap: 30px;
			align-items: center;
			margin-left: auto;
			font-size: 12px;
			// div{
			//   padding:0px 15px;
			//   color:$ww-dark;
			//   i{
			//     padding:0px 15px;
			//   }
			// }
		}
	}

	.table-controls {
		// border:solid 1px $ww-grey;
		background: #fff;
		border-bottom: none;
		display: flex;
		color: $ww-dark;
		font-size: 20px;
		font-weight: bold;
		padding: 16px 24px;
		h2 {
			font-size: 16pt;
			color: $ww-dark;
		}
	}

	.table-data {
		border-collapse: collapse;
		table-layout: fixed;
		thead {
			// border:solid 1px $ww-grey;
			tr {
				background: $ww-table-grey;
				// border:solid 1px $ww-grey;

				th {
					cursor: pointer;
					user-select: none;
					font-size: 10px;

					letter-spacing: 1px;
					text-transform: uppercase;
					// color:$ww-dark-grey;
					padding: 16px 8px;
				}
			}
		}
		tbody {
			// border:solid 1px $ww-grey;
			tr {
				background: #fff;
				border-bottom: solid 1px $ww-grey;

				td {
					font-size: 14px;
					color: $ww-dark-grey;
					padding: 20px 8px;
				}
			}
		}
		text-align: left;
		width: 100%;
	}
	.table-loading {
		height: 95px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
