<template>
    <div class="sidebar" :class="{'open':open}">
        <div class="menu">
            <div class="menu-item" v-for="(nav,i) in menu" :key="i" @click="$router.push(nav.link)" :class="{'active':$route.path.includes(nav.link)}">
                <i class="fad" :class="nav.icon"></i> {{ nav.label }}
            </div>
        </div>
    </div>
</template>
<script lang="ts">
export default {
    name:'sidebar',
    props:['open'],
    data(){
        return {
            activeMenu:-1,
            menu:[
                {
                    icon:'fa-heart',
                    label:'Dashboard',
                    link:'/dashboard'
                },
                {
                    icon:'fa-stethoscope',
                    label:'Medical',
                    link:'/medical'
                },
                {
                    icon:'fa-chart-line',
                    label:'Reports',
                    link:'/reports'
                },
                {
                    icon:'fa-comment-alt-lines',
                    label:'Messages',
                    link:'/messages'
                },
                {
                    icon:'fa-capsules',
                    label:'Prescriptions',
                    link:'/prescriptions'
                },
                {
                    icon:'fa-paw',
                    label:'Pets',
                    link:'/pets'
                }
            ]
        }
    }
}
</script>
<style lang="scss">
.sidebar{
    width:300px;
    background:#fff;
    position:fixed;
    left:0;
    top:70px;
    height:calc(100vh - 70px);
    z-index: 1000;
    border-right:solid 1px #eaeaea;
    padding-top:55px;
    transition:all .3s ease;
    box-shadow: none;
    @include media-query($small){
        position: fixed;
        width:250px;
        transform:translateX(-100%);
        box-shadow: none;
        
    }
    &.open{
        @include media-query($small){
            transform:translateX(0);
            box-shadow: 10px 5px 20px -10px #b2a3a7;
        }
    }
}
.menu{
    display:flex;
    flex-direction: column;
    padding:0px 25px;
    gap:10px;
    @include media-query($small){
        padding:0px 10px;
    }
    .menu-item{
        display:flex;
        align-items: center;
        gap:25px;
        padding:0px 25px;
        @include media-query($small){
            padding:0px 15px;
        }
        height:35px;
        color:$ww-text;
        font-size:12pt;
        transition:all .3s ease;
        border-radius: 10px;
        cursor: pointer;
        &:hover{
            background:lighten($ww-accent, 45%);
        }
        &.active{
            background:lighten($ww-accent, 45%);
        }
        i{
            font-size:12pt;
            color:$ww-accent;
        }
    }
}
</style>