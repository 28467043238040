<template>
	<div class="d-tabs">
		<slot :scope="inp"></slot>
	</div>
</template>
<script>
export default {
	name: "imdex-tabs",
	props: {
		value: {
			type: [Number, String]
		}
	},

	data() {
		return {
			events: []
		};
	},
	methods: {
		inp(e) {
			this.$emit("update:value", e);
		}
	}
};
</script>
<style scoped lang="scss">
.d-tabs {
	display: flex;
	align-items: flex-end;
}
</style>
