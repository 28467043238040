import { App as Application } from "vue";
import Input from "../inputs/text-input.vue";
import Button from "../inputs/button.vue";
import Dialog from "../additional_components/dialog.vue";
import RadioGroup from "../inputs/radio-group.vue";
import Radio from "../inputs/radio.vue";
import Select from "../inputs/select.vue";
import TextArea from "../inputs/textarea.vue";
import Checkbox from "../inputs/checkbox.vue";
import Slider from "../inputs/slider.vue";
import TabItem from "../additional_components/tab-item.vue";
import TabItems from "../additional_components/tab-items.vue";
import Tab from "../additional_components/tab.vue";
import Tabs from "../additional_components/tabs.vue";
import Accordion from "../additional_components/accordion.vue";
import Card from "../additional_components/card.vue";
import Chip from "../additional_components/chip.vue";
import Progress from "../additional_components/progress.vue";
import Sidebar from "../navigation/sidebar.vue";
import Status from "../additional_components/status.vue";
import Tooltip from "../additional_components/tooltip.vue";
import Table from "../additional_components/table.vue";
import Layout from "../additional_components/layout.vue";
import ButtonToggle from "../inputs/button-toggle.vue";
import Spinner from "../additional_components/spinner.vue";
import Switch from "../inputs/switch.vue";
import Toast from "../additional_components/toast.vue";
import Menu from "../additional_components/menu.vue";

import DateTimePicker from "../inputs/datetime-picker.vue";

export default function registerComponents(app: Application) {
	app.component("d-btn-toggle", ButtonToggle);
	app.component("d-layout", Layout);
	app.component("d-checkbox", Checkbox);
	app.component("d-text-field", Input);
	app.component("d-btn", Button);
	app.component("d-dialog", Dialog);
	app.component("d-radio-group", RadioGroup);
	app.component("d-radio", Radio);
	app.component("d-select", Select);
	app.component("d-textarea", TextArea);
	app.component("d-slider", Slider);
	app.component("d-tabs", Tabs);
	app.component("d-tab", Tab);
	app.component("d-tab-item", TabItem);
	app.component("d-tab-items", TabItems);
	app.component("d-accordion", Accordion);
	app.component("d-card", Card);
	app.component("d-chip", Chip);
	app.component("d-progress", Progress);
	app.component("d-sidebar", Sidebar);
	app.component("d-status", Status);
	app.component("d-tooltip", Tooltip);
	app.component("d-table", Table);
	app.component("d-spinner", Spinner);
	app.component("d-switch", Switch);
	app.component("d-toast", Toast);
	app.component("d-menu", Menu);
	app.component("d-datetime-picker", DateTimePicker);
}
