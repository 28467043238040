<template>
    <div class="header">
        <div class="header-image">
            <img src="@/assets/SVG/whisker.svg" alt="">
            <span class="upcase"></span>
        </div>
        <div class="header-right">
            <div v-if="!isLoggedIn" class="header-item">
                <d-btn small text> Sign In </d-btn>
            </div>
            <div v-if="isLoggedIn" class="header-item welcome-text">
                Welcome {{ user.name }} 
            </div>
            <div v-if="isLoggedIn" class="header-item">
                <d-btn @click="logout" small text> Sign Out </d-btn>
            </div>
        </div>
    </div>
</template>
<script>
import { useAuthStore } from '@/store/authStore'
import { storeToRefs } from 'pinia';
import {defineComponent} from 'vue';
export default defineComponent({
    name:'Header',
    setup(){
        const authStore = useAuthStore();
        const {user} = storeToRefs(useAuthStore());
        const {logout} = useAuthStore();
        return {
            user,
            authStore,
            logout
        }
    },
    data(){
        return {

        }
    },
    mounted(){

    },
    methods:{

    },
    computed:{
        isLoggedIn() {
            return this.user.name != null
        }
    },
})
</script>
<style scoped lang="scss">
.header{
    max-height:70px;
    height:100%;
    width:100%;
    position:fixed;
    top:0;
    left:0;
    background:#fff;
    transition:all .3s ease;
    display:flex;
    padding:10px 5%;
    z-index: 1001;
    
    .header-image{
        @include media-query($small){
            left:48%;
            transform:translateX(-50%) translateY(-50%);
            position:absolute;
            top:50%;

        }
        display:flex;
        align-items: center;
        span{
            margin-left:10px;
        }
        img{
            
            height:35px
        }
    }
    .header-right{
        margin-left: auto;
        display:flex;
        align-items: center;
        gap:15px;
        .header-item{
            &.welcome-text{
                @include media-query($small){
                    display:none;
                }
            }
            height:100%;
            display:flex;
            align-items: center;
            justify-content: center;
            font-size:12pt;
            color:$ww-text;
        }
    }
}
</style>
