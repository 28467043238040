import { createApp, App as Application, markRaw } from "vue";
import Vue from 'vue';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { Router } from "vue-router";
import './assets/css/all.css';
import './assets/css/animate.css';
import './assets/css/main.css';
import "cropperjs/dist/cropper.css";
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { GetGuid } from "./utils/Utilities";

// import { ClientTable} from 'vue-tables-2';
// import VueMq from "vue-mq";
declare module "pinia" {
	export interface PiniaCustomProperties {
		router: Router;
	}
}


import registerComponents from "./plugins/inputs";

async function bootstrap() {
	const app = createApp(App);
	registerComponents(app);
	const pinia = createPinia();
	pinia.use(({ store }) => {
		store.router = markRaw(router);
	});
	pinia.use(piniaPluginPersistedstate);

	app.config.globalProperties.$uuid4 = () => GetGuid().toString();
	app.use(pinia).use(router).mount("#app");
}

bootstrap();

// Vue.config.productionTip = false
// Vue.use(ClientTable, {options : {}, useVuex : false, theme: 'bootstrap3', template : 'default'});

// createApp(App).use(store).use(VueMq, {
//     breakpoints: {
//       sm: 728,
//       md: 1200,
//       lg: Infinity
//     }
//   }).use(ClientTable, {options : {}, useVuex : false, theme: 'bootstrap3', template : 'default'}).use(Vuex).use(router).mount('#app')
