<template>
	<div>
		<transition name="block">
			<div
				v-if="value"
				class="d-status animated"
				:class="{
					[type]: type,
					'bottom-left': position == 'bottom-left',
					'top-left': position == 'top-left',
					'bottom-right': position == 'bottom-right',
					'top-right': position == 'top-right'
				}"
			>
				<i
					class="fal"
					:class="{
						'fa-check': type == 'success',
						'fa-exclamation-circle': type == 'error',
						'fa-exclamation-triangle': type == 'warning'
					}"
				></i>
				{{ message }}
				<div class="actions">
					<v-btn @click="$emit('update:value', false)" text compact>Ok</v-btn>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
export default {
	name: "imdex-status",
	props: {
		type: {
			type: String,
			default: "basic"
		},
		value: {
			type: Boolean,
			default: false
		},
		message: {
			type: String,
			default: ""
		},
		clearable: {
			type: Boolean,
			default: true
		},
		position: {
			type: String,
			default: "bottom-right"
		},
		delay: {
			type: Number,
			default: 3000
		}
	},
	data() {
		return {};
	},
	watch: {
		value(v) {
			if (v) {
				setTimeout(() => {
					this.$emit("update:value", false);
				}, this.delay);
			}
		}
	}
};
</script>
<style scoped lang="scss">
.block-enter {
	opacity: 0;
	transform: translateY(100px);
}
.block-enter-active {
	transition: all 0.5s ease;
	transform: translateY(0);
}
.block-leave {
}
.block-leave-active {
	transition: all 0.5s ease;
	opacity: 0;
	transform: translateY(100px);
}
.d-status {
	position: fixed;
	height: 70px;
	display: flex;
	align-items: center;
	background: $ww-primary;
	padding: 24px;
	z-index: 100000;
	&.basic {
		background: $ww-primary;
		color: #fff;
		i {
			color: #fff;
		}
		.btn-b {
			color: #fff !important;
			&:hover {
				background: #ffffff30 !important;
			}
		}
	}
	&.error {
		background: $ww-error;
		color: #fff;
		i {
			color: #fff;
		}
		.btn-b {
			color: #fff !important;
			&:hover {
				background: #ffffff30 !important;
			}
		}
	}
	&.success {
		background: $ww-success;
		color: $ww-primary;
		i {
			color: $ww-primary;
		}
		.btn-b {
			color: $ww-primary !important;
			&:hover {
				background: #ffffff30 !important;
			}
		}
	}
	&.warning {
		background: $ww-warning;
		color: $ww-primary;
		i {
			color: $ww-primary;
		}
		.btn-b {
			color: $ww-primary !important;
			&:hover {
				background: #ffffff30 !important;
			}
		}
	}
	i {
		margin-right: 16px;
	}
	.actions {
		margin-left: 10px;
	}
	&.top-right {
		top: 15px;
		right: 15px;
	}
	&.top-left {
		top: 15px;
		left: 15px;
	}
	&.bottom-right {
		bottom: 15px;
		right: 15px;
	}
	&.bottom-left {
		bottom: 15px;
		left: 15px;
	}
}
</style>
